import styled, { ThemeProvider } from 'styled-components/macro'
import { Outlet } from 'react-router-dom'

import OfferProvider from './context/OfferProvider'
import GlobalStyle from './theme/globalStyle'
import { defaultTheme } from './theme/defaultTheme'

const AppWrapper = styled.div`
  // padding: 2.2rem 2.2rem 1rem;
`

const App = () => {
  return (
    <OfferProvider>
      <ThemeProvider theme={defaultTheme}>
        <>
          <GlobalStyle theme={defaultTheme} />
          <AppWrapper>
            <main>
              <Outlet />
            </main>
          </AppWrapper>
        </>
      </ ThemeProvider>
    </OfferProvider>
  )
}

export default App
