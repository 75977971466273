import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components/macro'

import OffersData from '../../assets/data/offers.json'
import { ReactComponent as IconCheck } from '../../assets/images/icons/check.svg'

import pen from '../../assets/images/icons/pen.svg'
import folder from '../../assets/images/icons/folder-open-o.svg'
import formulaire from '../../assets/images/icons/formulaire.svg'

const StepWrapper = styled.div`
  position: relative;
  bottom: 3rem;
  flex: 3;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  .step0-title {
    position: relative;
    font-size: 3.5rem;
    font-weight: 700;
    letter-spacing: 0.12px;
    text-align: center;
    > span {
      display: block;
      font-size: 4.5rem;
      padding-top: 1rem;
      > strong {
        font-size: 6.5rem;
      }
    }
  }
  .step0-intro {
    margin: 0 auto 3rem;
    font-size: 1.8rem;
    line-height: 1.8;
  }
  .step0-frame {
    align-self: stretch;
    margin-bottom: 3rem;
    padding: 1rem 4rem 2rem;
    background-color: rgba(46, 216, 195, 0.1);
    .step0-frame-intro {
      font-size: 1.6rem;
      font-weight: 700;
      color: #ee2464;
    }
    .step0-frame-list {
      margin-top: 2rem;
      list-style: none;
      > li {
        display: flex;
        align-items: flex-start;
        text-align: left;
        margin-bottom: 1.8rem;
        font-size: 1.6rem;
        line-height: 1.8;
        font-weight: bold;
        justify-content: center;

        > svg {
          display: block;
          margin: 7px 10px 0 0;
          flex: 0 0 14px;
          stroke: ${(props) => props.theme.colors.secondaryColor} !important;
          > path {
            stroke: ${(props) => props.theme.colors.secondaryColor} !important;
          }
        }
      }
    }
  }

  .intro {
    font-size: 16px;
    font-weight: bold;
    line-height: 1.63;
    letter-spacing: 0.06px;
    color: #ee2464;
  }
  .step0-frame-intro:after,
  .intro:after {
    content: '';
    display: block;
    border-bottom: solid 2px #f7205b;
    width: 23px;
    position: relative;
    left: 50%;
    padding: 0.5rem 0;
  }
  .reduction {
    color: #ee2464;
    font-size: 12px;
    border-bottom: solid 1px #f7205b;
  }
  .open {
    display: flex;
    justify-content: center;
    margin-left: -3rem;
  }
  p {
    padding: 5% 3%;
    font-size: 1.3rem;
    line-height: 1.29;
    letter-spacing: 0.06px;
    text-align: center;
    color: #014751;
  }
`

const elements = [
  {
    id: 1,
    text: 'Vous remplissez le formulaire en 5 min, pas plus !',
    icon: formulaire
  },
  {
    id: 2,
    text: 'Vous  préparez et transmettez vos justificatifs',
    icon: folder
  },
  {
    id: 3,
    text: 'Vous signez votre contrat en ligne',
    icon: pen
  }
]

const Landing = ({ selectedOffer, changeStep, changeSubStep }) => {
  let data = OffersData[selectedOffer]

    if (data === undefined) {
      window.open('/souscription', "_self");
  }
  return (
    <StepWrapper>
      <h1 className="step0-title">
        Vous avez choisi l'offre à
        <span>
          <strong>{data.monthlyPrice}€</strong>/mois
        </span>
      </h1>
      <p className="step0-intro">
        Vous souhaitez ouvrir un Compte Courant à la Banque 2000 <br /> et nous vous remercions de
        votre confiance.
      </p>
      <div className="step0-frame">
        <p className="intro">Comment ouvrir un compte &nbsp;?</p>
        <div className="open">
          {elements.map((element) => {
            return (
              <div className="open-account" key={element.id}>
                <img className="open-icon" src={element.icon} alt="icons" />
                <p>{element.text}</p>
              </div>
            )
          })}
        </div>
      </div>
      <div className="step0-frame">
        <p className="step0-frame-intro">Je prépare mes justicatifs</p>
        <ul className="step0-frame-list">
          <li>
            <IconCheck /> Une pièce d’identité en cours de validité
          </li>
          <li>
            <IconCheck /> Un justificatif de domicile récent
          </li>
          <li>
            <IconCheck /> Le(s) dernier(s) justificatif(s) de revenus
          </li>
          <li>
            <IconCheck /> RIB
          </li>
        </ul>
        <Link to="#" className="reduction">
          Comment envoyer mes justificatifs
        </Link>
      </div>
      <button
        className="cta"
        onClick={() => {
          changeSubStep(0)
          changeStep(1)
        }}
      >
        Ouvrir mon compte
      </button>
    </StepWrapper>
  )
}

Landing.displayName = 'SubscriptionLanding'

export default Landing
