import React, { useMemo, useState } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import { Formik, Form } from 'formik'
import * as yup from 'yup'
import styled from 'styled-components/macro'
import landingFormModel from '../../assets/data/landingFormModel'
import checkForm from '../../assets/images/icons/check-form.svg'
import selectArrow from '../../assets/images/icons/select-arrow.svg'
import { renderForm } from '../../helpers'
import Modal from '../../components/Modal'
import xmark from '../../assets/images/icons/icon-cross.svg'

const SubscriptionSectionWrapper = styled.div`
  height: 100vh;
`
const ContentWrapper = styled.div`
  display: flex;
  height: calc(100vh - 95px);

  .form-title {
    margin-bottom: 3rem;
    font-size: 4.6rem;
    font-weight: 700;
    text-align: center;
    color: #fff;
  }

  h2 {
    margin-top: 3rem;
    color: #ee2464;
    font-size: 1.6rem;
    font-weight: 700;
  }

  .form-container {
    flex: 4;
    padding: 0 1rem;
    @media (min-width: 768px) {
      padding: 0 3rem;
    }

    .form-content {
      margin-left: auto;
      margin-right: auto;
      padding: 2rem;
      max-width: 64rem;
      width: 100%;
      background-color: #ffffff;
      @media (min-width: 768px) {
        padding: 2rem 8rem;
        margin-left: 30%;
        max-width: 50rem;
      }
      @media (min-width: 1200px) {
        margin-left: 40%;
        max-width: 64rem;
      }
    }

    .input-wrapper {
      position: relative;
      margin: 0 0 1.5rem;

      .input-label {
        position: absolute;
        top: 16px;
        left: 12px;
        display: block;
        padding: 0 3px;
        font-size: 1.4rem;
        color: ${(props) => props.theme.colors.primaryColor};
        background-color: ${(props) => props.theme.colors.whiteColor};
        pointer-events: none;
        font-weight: 700;
      }

      input {
        width: 100%;
        height: 5rem;
        padding: 0 15px;
        border: 1px solid ${(props) => props.theme.colors.primaryColor};
        background-color: transparent;
        color: inherit;

        &::placeholder {
          color: inherit;
        }

        &:focus + .input-label,
        &:not(:placeholder-shown) + label {
          transform-origin: left center;
          transform: translateY(-25px) scale(0.8);
        }
      }

      &.input-error {
        // .input-label {
          //   color: ${(props) => props.theme.colors.errorColor};
        // }
        //
        // input {
          //   border: 1px solid ${(props) => props.theme.colors.errorColor};
        //   background-color: rgba(183, 0, 0, 0.05);
        // }
      }

      .input-error-message {
        margin-top: 1rem;
        color: ${(props) => props.theme.colors.errorColor};
        font-size: 1rem;
        text-align: left;
      }
    }

    .select-wrapper {
      position: relative;
      margin: 0 0 1.5rem;

      select {
        width: 100%;
        height: 5rem;
        padding: 0 15px;
        border: 1px solid ${(props) => props.theme.colors.primaryColor};
        background-image: url(${selectArrow});
        background-repeat: no-repeat;
        background-position: right 1rem top 2.2rem;
        color: inherit;
        appearance: none;
        font-family: inherit;
        font-size: 1.4rem;

        &:focus + .select-label,
        &:not(:placeholder-shown) + label {
          transform-origin: left center;
          transform: translateY(-25px) scale(0.8);
        }
      }

      &.select-error {
        // .select-label {
          //   color: ${(props) => props.theme.colors.errorColor};
        // }
        //
        // select {
          //   color: ${(props) => props.theme.colors.errorColor};
          //   border: 1px solid ${(props) => props.theme.colors.errorColor};
        //   background-color: rgba(183, 0, 0, 0.05);
        // }
      }

      .select-error-message {
        margin-top: 1rem;
        color: ${(props) => props.theme.colors.errorColor};
        font-size: 1rem;
        text-align: left;
      }
    }

    .radio-group,
    .checkbox-group {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      flex: 1;

      &.is-inline {
        flex-direction: column;

        .radio-wrapper,
        .checkbox-wrapper {
          width: 100%;
        }
      }
    }

    .input-error-message {
      margin: -0.5rem 0 1.5rem;
      color: ${(props) => props.theme.colors.errorColor};
      font-size: 1rem;
      text-align: left;
      width: 100%;
    }
  }

  .radio-wrapper,
  .checkbox-wrapper {
    position: relative;
    margin-bottom: 1.5rem;
    width: calc((100% - 1.5rem) / 2);
    height: 5rem;
    cursor: pointer;

    * {
      cursor: pointer;
    }

    .input-label {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      width: 100%;
      height: 100%;
      padding: 0 15px;
      font-size: 1.4rem;
      font-weight: 600;
      color: ${(props) => props.theme.colors.primaryColor};
      border-style: solid;
      border-width: 1px;
      border-color: ${(props) => props.theme.colors.primaryColor};
      background-color: ${(props) => props.theme.colors.whiteColor};
    }

    input[type='radio'],
    input[type='checkbox'] {
      position: absolute;
      left: -99999px;

      &:checked + .input-label {
        border-color: ${(props) => props.theme.colors.secondaryColor};
        background-color: rgba(46, 216, 195, 0.05);

        &:after {
          content: '';
          display: block;
          width: 16px;
          height: 16px;
          position: absolute;
          top: 15px;
          right: 15px;
          background-image: url(${checkForm});
          background-repeat: no-repeat;
          background-size: 100%;
        }
      }
    }
  }

  .section-title,
  .select-label {
    display: block;
    margin: 2rem 0 0.5rem;
    font-size: 1.4rem;
    color: ${(props) => props.theme.colors.primaryColor};
    background-color: ${(props) => props.theme.colors.whiteColor};
    pointer-events: none;
    text-align: left;

    &:is(.select-label) {
      font-weight: 700;
    }
  }

  form:has(.substep-big-number) .step-wrapper {
    display: block;

    .input-wrapper.substep-big-number {
      float: left;
      display: inline-block;
      width: 25%;
      margin-bottom: 6rem;

      input {
        width: 85px;
        height: 115px;
        border: solid 2px #2ed8c3;
        border-radius: 1rem;
        color: ${(props) => props.theme.colors.primaryColor};
        text-align: center;
        font-size: 5rem;
        font-weight: 700;
      }
    }
  }

  form:where(.step_1_0, .step_1_3, form.step_1_4) { {
    .input-wrapper:has(input:where([name="phone_prefix"], [name="zip"], [name="contract_type"])) {
      grid-column: span 1;
    }

    .input-wrapper:has(input:where([name="city"], [name="phone"], [name="contract_start_date"])) {
      grid-column: span 2;
    }
  }
  }

  .form-notice {
    font-size: 12px;
    text-align: left;
  }

  .actions {
    flex-wrap: wrap;
    margin-top: 2.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    clear: both;
  }

  .popupTitle {
    font-size: 2.7rem;
    text-align: center;
    font-weight: 600;
    margin: 4rem 0;
    padding: 0 5%;
  }

  button.btn-prev {
    color: #ee2464;
    padding: 1rem 4rem;
    margin: 1rem;
    font-size: 16px;
    font-weight: 700;
    border-radius: 22.5px;
    border: 2px solid #ee2464;
  }

  button.btn-next {
    background-color: #ee2464;
    color: #FFF;
    border: none;
    padding: 1rem 4rem;
    margin: 1rem;
    font-size: 16px;
    font-weight: 700;
    border-radius: 22.5px;
  }

  button.cta {
    margin: 0 auto;
    display: block;
  }
}
`

const Subscription = () => {
  const navigation = useNavigate()
  const { formId, formFields } = landingFormModel
  const [currentStep, setCurrenStep] = useState(1)
  const [initialFields, setInitialFields] = useState([])
  const [onboardingSchema, setOnboardingSchema] = useState({})
  const [isLoadingInitialFields, setIsLoadingInitialFields] = useState(true)
  const [openModal, setOpenModal] = useState(false)

  useMemo(() => {
    let initialSchema = {}
    const initFields = Object.keys(formFields).reduce((acc, key) => {
      const stepModel = formFields[key]
      for (let i = 0; i < stepModel.length; i++) {
        if (key === `step_${currentStep}`) {
          const field = stepModel[i]
          if (field.type !== 'section') {
            acc[field.name] = field.initialValue
            initialSchema[field.name] = field.validationSchema
          }
        }
      }
      return acc
    }, {})
    setInitialFields(initFields)
    setOnboardingSchema(yup.object().shape(initialSchema))
    setIsLoadingInitialFields(false)
  }, [currentStep, formFields])

  const handleSubmit = (values) => {
    console.log('submit')
    console.log(values)

    // @TODO A revoir...
    Object.keys(formFields).forEach((key) => {
      const stepModel = formFields[key]
      for (let i = 0; i < stepModel.length; i++) {
        if (key === `step_${currentStep}`) {
          const field = stepModel[i]
          field.initialValue = values[field.name]
        }
      }
    })

    if (currentStep === 5) {
      setOpenModal(true)
    } else {
      setCurrenStep(currentStep + 1)
    }
  }

  return (
    <>
      <SubscriptionSectionWrapper>
        <ContentWrapper className="container">
          <div className="form-container">
            {isLoadingInitialFields ? (
              <p>Loading...</p>
            ) : (
              <Formik
                enableReinitialize
                initialValues={initialFields}
                validationSchema={onboardingSchema}
                onSubmit={handleSubmit}
              >
                {({ isSubmitting, errors, touched }) => (
                  <Form id={formId}>
                    <h1 className="form-title">Trouvons la meilleure des offres pour vous !</h1>

                    <div className="form-content">
                      <h2>{currentStep > 2 ? 'Vos habitudes' : 'Votre profil'}</h2>
                      {renderForm(formFields[`step_${currentStep}`], errors, touched)}

                      <div className="form-notice">*Champ obligatoire</div>

                      <div className="actions">
                        {currentStep > 1 && (
                          <button
                            onClick={() => setCurrenStep(currentStep - 1)}
                            type="button"
                            className="btn-prev"
                          >
                            Précédent
                          </button>
                        )}
                        <button type="submit" className="btn-next" disabled={isSubmitting}>
                          {currentStep === 5 ? 'Découvrir mon offre' : 'Continuer'}
                        </button>
                      </div>

                      {openModal && (
                        <Modal>
                          <div className="body">
                            <button
                              className="btn-close"
                              onClick={() => {
                                setOpenModal(false)
                              }}
                            >
                              <img src={xmark} alt="close" />
                            </button>
                            <p className="popupTitle">
                              Merci pour vous réponses. <br /> Vous pouvez continuer vers l'offre
                              qui vous correspond au mieux.
                            </p>
                          </div>
                          <button className="cta" onClick={() => navigation('/souscription')}>
                            Je continue
                          </button>
                        </Modal>
                      )}
                    </div>
                  </Form>
                )}
              </Formik>
            )}
          </div>
        </ContentWrapper>
        <Outlet />
      </SubscriptionSectionWrapper>
    </>
  )
}

export default Subscription
