import React from 'react'
import ReactDOM from 'react-dom/client'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'

import { registerLocale, setDefaultLocale } from 'react-datepicker'
import fr from 'date-fns/locale/fr'

import reportWebVitals from './reportWebVitals'
import App from './App'
import ErrorPage from './views/ErrorPage'
import Landing from './views/Landing/Landing'
import Subscription from './views/subscription/Subscription'
import MainSection from './views/main/MainSection'
import SubscriptionPage from './views/main/SubscriptionPage'
import StylesPage from './views/main/StylesPage'

registerLocale('fr', fr)
setDefaultLocale('fr')

const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: '/souscrire',
        element: <Subscription />
      },
      {
        path: '/',
        element: <MainSection />,
        children:[
          {
            path: '/',
            element: <Landing />
          },
          {
            path: 'souscription',
            element: <SubscriptionPage />
          },
          {
            path: 'presentation',
            element: <p>Présentation</p>
          },
          {
            path: 'contact',
            element: <p>Contact</p>
          },
          {
            path: 'styles',
            element: <StylesPage />
          }
        ]
      }
    ]
  }
])

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <RouterProvider router={router} />
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
