import * as yup from 'yup'

const numberRegExp = /[0-9]/g

const model = {
  formId: 'landingForm',
  formFields: {
    step_1: [
      {
        type: 'section',
        label: 'Quel âge avez-vous ?*'
      },
      {
        name: 'age',
        type: 'radio',
        initialValue: '',
        label: 'Quel âge avez-vous ?',
        validationSchema: yup.string().required("Vous n'avez rien sélectionné"),
        options: [
          {
            label: 'moins de 18 ans',
            value: '1'
          },
          {
            label: '+ De 25 ans',
            value: '2'
          }
        ]
      },
      {
        name: 'house_size',
        type: 'text',
        initialValue: '',
        mandatory: true,
        label: 'Combien de personnes constituent votre foyer ?',
        validationSchema: yup
          .string()
          .matches(numberRegExp, 'Ce champ est invalide').required('Ce champ est invalide')
      }
    ],
    step_2: [
      {
        type: 'section',
        label: 'Voyagez-vous régulièrement en Europe ?*'
      },
      {
        name: 'inside_europe_travel',
        type: 'radio',
        initialValue: '',
        label: 'Voyagez-vous régulièrement en Europe ?*',
        validationSchema: yup.string().required("Vous n'avez rien sélectionné"),
        options: [
          {
            label: 'Souvent',
            value: '1'
          },
          {
            label: 'De temps en temps',
            value: '2'
          },
          {
            label: 'Jamais',
            value: '3'
          }
        ]
      }
    ],
    step_3: [
      {
        type: 'section',
        label: 'Voyagez-vous régulièrement hors Europe ?*'
      },
      {
        name: 'outside_europe_travel',
        type: 'radio',
        initialValue: '',
        label: 'Voyagez-vous régulièrement en Europe ?*',
        validationSchema: yup.string().required("Vous n'avez rien sélectionné"),
        options: [
          {
            label: 'Souvent',
            value: '1'
          },
          {
            label: 'De temps en temps',
            value: '2'
          },
          {
            label: 'Jamais',
            value: '3'
          }
        ]
      }
    ],
    step_4: [
      {
        type: 'section',
        label:
          'Quand vous avez un train, êtes vous plutôt premier dans le wagon ou à courir après le train ?*'
      },
      {
        name: 'travel_timing',
        type: 'radio',
        initialValue: '',
        label:
          'Quand vous avez un train, êtes vous plutôt premier dans le wagon ou à courir après le train ?*',
        validationSchema: yup.string().required("Vous n'avez rien sélectionné"),
        style: 'inline',
        options: [
          {
            label: "Je suis toujours là une heure avant et j'ai même le temps de prendre un café",
            value: '1'
          },
          {
            label: 'Je suis là en même temps que le train',
            value: '2'
          },
          {
            label:
              "Je suis toujours en retard mais parfois j'arrive à négocier pour entrer dans le train",
            value: '3'
          }
        ]
      }
    ],
    step_5: [
      {
        type: 'section',
        label:
          'Êtes-vous plutôt du genre à organiser votre budget au centime près ou à découvrir vos dépenses à la fin du mois ?*'
      },
      {
        name: 'travel_organize',
        type: 'radio',
        initialValue: '',
        label:
          'Êtes-vous plutôt du genre à organiser votre budget au centime près ou à découvrir vos dépenses à la fin du mois ?*',
        validationSchema: yup.string().required("Vous n'avez rien sélectionné"),
        options: [
          {
            label: 'Débit différé',
            value: '1'
          },
          {
            label: 'Débit immédiat',
            value: '2'
          }
        ]
      }
    ]
  }
}

export default model
