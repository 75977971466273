import React from 'react'
import styled from 'styled-components/macro'

import SignupForm from '../../components/Form'
import Tracking from '../../components/Tracking'
const StylesPageWrapper = styled.div`
`

const StylesPage = () => {
	return (
		<StylesPageWrapper className='container'>
      Styles Page
	    <Tracking />
      <SignupForm />
    </StylesPageWrapper>
	)
}

export default StylesPage
