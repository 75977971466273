import React, { useEffect, useMemo, useState } from 'react'
import { Outlet } from 'react-router-dom'
import { Formik, Form } from 'formik'
import * as yup from 'yup'
import styled from 'styled-components/macro'

import { useOffer } from '../../context/OfferProvider'
import onboardingFormModel from '../../assets/data/onboardingFormModel'
import Landing from './Landing'
import Profile from './Profile'
import Recap from './Recap'
import Success from './Success'
import SignerContart from '../../components/SignerContart'
import Header from '../../components/Header'
import Tracking from '../../components/Tracking'
import ProgressBar from '../../components/ProgressBar'
import Loader from '../../components/Loader'
import checkForm from '../../assets/images/icons/check-form.svg'
import selectArrow from '../../assets/images/icons/select-arrow.svg'
import franceConnectImage from '../../assets/images/france-connect-indentification.jpeg'
import { renderForm } from '../../helpers'
import 'react-datepicker/dist/react-datepicker.css'

const SubscriptionSectionWrapper = styled.div`
  height: 100vh;
`
const ContentWrapper = styled.div`
  display: flex;
  height: calc(100vh - 95px);

  .form-title {
    margin-bottom: 3rem;
    font-size: 3.4rem;
    font-weight: 700;
    text-align: center;
  }

  .form-container {
    flex: 4;
    padding-right: 3rem;

    @media (min-width: 768px) {
      padding: 0 3rem;
    }

    form:is(.step_3, .step_5) > :not(:first-child) {
      margin-left: auto;
      margin-right: auto;
      max-width: 38rem;
    }

    .input-wrapper {
      position: relative;
      margin: 0 0 1.5rem;

      .input-label {
        position: absolute;
        top: 16px;
        left: 12px;
        display: block;
        padding: 0 3px;
        font-size: 1.4rem;
        color: ${(props) => props.theme.colors.primaryColor};
        background-color: ${(props) => props.theme.colors.whiteColor};
        pointer-events: none;
        font-weight: 700;
      }

      &.input-file .input-wrapper-inner,
      input {
        width: 100%;
        height: 5rem;
        padding: 0 15px;
        border: 1px solid ${(props) => props.theme.colors.primaryColor};
        background-color: transparent;
        color: inherit;

        &::placeholder {
          color: inherit;
          font-family: Trenda, sans-serif;
          font-size: 1.4rem;
          font-weight: 700;
        }

        &:focus + .input-label,
        &:not(:placeholder-shown) + label {
          transform-origin: left center;
          transform: translateY(-25px) scale(0.8);
        }
      }

      &.input-error {
        // .input-label {
        //   color: ${(props) => props.theme.colors.errorColor};
        // }
        // input {
        //   border: 1px solid ${(props) => props.theme.colors.errorColor};
        //   background-color: rgba(183, 0, 0, 0.05);
        // }
      }

      .input-error-message {
        margin-top: 1rem;
        color: ${(props) => props.theme.colors.errorColor};
        font-size: 1rem;
        text-align: left;
      }

      &.input-file {
        position: relative;

        &.is-valid .input-wrapper-inner {
          background-color: rgba(46, 216, 195, 0.05);
          border: solid 1px #2ed8c3;
        }

        .input-file-filename {
          position: absolute;
          top: 1.6rem;
          font-size: 1.2rem;
          font-weight: 700;
        }

        input {
          position: relative;
          cursor: pointer;
          opacity: 0;
        }

        &:not(.is-valid) label {
          transform: none !important;
        }

        svg {
          position: absolute;
          right: 1rem;
          top: 1.2rem;
        }
      }

      .btn-delete {
        position: absolute;

        .icon-delete {
          width: 15px;
          height: 15px;
        }
      }
    }

    .select-wrapper {
      position: relative;
      margin: 0 0 1.5rem;

      select {
        width: 100%;
        height: 5rem;
        padding: 0 15px;
        border: 1px solid ${(props) => props.theme.colors.primaryColor};
        background-image: url(${selectArrow});
        background-repeat: no-repeat;
        background-position: right 1rem top 2.2rem;
        color: inherit;
        appearance: none;
        font-family: inherit;
        font-size: 1.4rem;

        &:focus + .select-label,
        &:not(:placeholder-shown) + label {
          transform-origin: left center;
          transform: translateY(-25px) scale(0.8);
        }
      }

      &.select-error {
        // .select-label {
        //   color: ${(props) => props.theme.colors.errorColor};
        // }
        // select {
        //   color: ${(props) => props.theme.colors.errorColor};
        //   border: 1px solid ${(props) => props.theme.colors.errorColor};
        //   background-color: rgba(183, 0, 0, 0.05);
        // }
      }

      .select-error-message {
        margin-top: 1rem;
        color: ${(props) => props.theme.colors.errorColor};
        font-size: 1rem;
        text-align: left;
      }
    }

    & :where(.radio-group, .checkbox-group):not(.is-simple) {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      flex: 1;

      .input-error-message {
        margin: -0.5rem 0 1.5rem;
        color: ${(props) => props.theme.colors.errorColor};
        font-size: 1rem;
        text-align: left;
        width: 100%;
      }

      & :where(.radio-wrapper, .checkbox-wrapper) {
        position: relative;
        margin-bottom: 1.5rem;
        width: calc((100% - 1.5rem) / 2);
        height: 5rem;
        cursor: pointer;

        * {
          cursor: pointer;
        }

        .input-label {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          width: 100%;
          height: 100%;
          padding: 0 15px;
          font-size: 1.4rem;
          color: ${(props) => props.theme.colors.primaryColor};
          border-style: solid;
          border-width: 1px;
          border-color: ${(props) => props.theme.colors.primaryColor};
          background-color: ${(props) => props.theme.colors.whiteColor};
        }

        input[type='radio'],
        input[type='checkbox'] {
          position: absolute;
          left: -99999px;

          &:checked + .input-label {
            border-color: ${(props) => props.theme.colors.secondaryColor};
            background-color: rgba(46, 216, 195, 0.05);

            &:after {
              content: '';
              display: block;
              width: 16px;
              height: 16px;
              position: absolute;
              top: 15px;
              right: 15px;
              background-image: url(${checkForm});
              background-repeat: no-repeat;
              background-size: 100%;
            }
          }
        }
      }
    }

    & :where(.radio-group, .checkbox-group):is(.is-simple) {
      .checkbox-wrapper {
        display: flex;
        align-items: flex-start;
        margin-bottom: 1rem;
      }

      label {
        margin-left: 1rem;
        font-size: 1.3rem;
      }

      .input-error-message {
        margin: -0.5rem 0 1.5rem;
        color: ${(props) => props.theme.colors.errorColor};
        font-size: 1rem;
        text-align: left;
        width: 100%;
      }
    }

    .section-title,
    .select-label {
      display: block;
      margin: 2rem 0 0.5rem;
      font-size: 1.4rem;
      color: ${(props) => props.theme.colors.primaryColor};
      background-color: ${(props) => props.theme.colors.whiteColor};
      pointer-events: none;
      text-align: left;
      font-weight: 700;
    }

    form:has(.substep-big-number) .step-wrapper {
      display: block;

      .input-wrapper.substep-big-number {
        float: left;
        display: inline-block;
        width: 25%;
        margin-bottom: 6rem;

        input {
          width: 85px;
          height: 115px;
          border: solid 2px #2ed8c3;
          border-radius: 1rem;
          color: ${(props) => props.theme.colors.primaryColor};
          text-align: center;
          font-size: 5rem;
          font-weight: 700;
        }
      }
    }

    form:where(.step_1_0, .step_1_3, form.step_1_4) {
       {
        .input-wrapper:has(
            input:where([name='phone_prefix'], [name='zip'], [name='contract_type'])
          ) {
          grid-column: span 1;
        }

        .input-wrapper:has(
            input:where([name='city'], [name='phone'], [name='contract_start_date'])
          ) {
          grid-column: span 2;
        }
      }
    }

    .form-notice {
      font-size: 12px;
      text-align: left;
    }

    .actions-wrapper {
      margin-top: 2.5rem;
      display: flex;
      justify-content: center;
      align-items: center;
      clear: both;

      .btnSms {
        margin-top: -3.5rem;
      }
    }
  }

  .BtnValide {
    padding: 0 8.4rem;
    font-weight: 800;
  }

  .btnSms {
    padding: 0 1.4rem;
    border-radius: 22px;
    border: solid 2px #014751;
    height: 4.5rem;
    font-size: 16px;
    font-weight: 600;
    color: #014751;
    margin-top: -3.5rem;
  }

  button.btnSms:after {
    background: none;
  }

  .react-datepicker {
    font-size: 1.3rem !important;
  }

  .react-datepicker__current-month {
    font-size: 1.5rem !important;
  }

  .react-datepicker__header {
    padding-top: 6px !important;
  }

  .react-datepicker__navigation {
    top: 13px !important;
  }

  .react-datepicker__day-name,
  .react-datepicker__day {
    margin: 0.5rem !important;
  }
`

const STEP_TITLES = {
  1: 'Renseignez vos informations',
  2: '',
  3: 'Choix du mode de prélèvement',
  4: 'Veuillez signer pour valider votre contrat',
  5: 'Transmettez-nous vos documents',
  6: ''
}

const PROFILE_STEP_SETTINGS = {
  step_1_0: {
    title: 'Votre identité',
    progressValue: '20%'
  },
  step_1_1: {
    description:
      'Votre compte à bien été crée, Veuillez saisir votre code de sécurité reçu par sms.',
    progressValue: '20%'
  },
  step_1_2: {
    title: 'Utilisation de votre compte',
    description: 'Pourquoi souhaitez-vous ouvrir un compte chez Banque 2000 ?',
    progressValue: '40%'
  },
  step_1_3: {
    title: 'Votre adresse',
    progressValue: '60%'
  },
  step_1_4: {
    title: 'Situation professionnelle',
    progressValue: '80%'
  },
  step_1_5: {
    title: 'Situation familiale',
    progressValue: '100%'
  }
}

const Subscription = () => {
  const { selectedOffer, currentStep, changeStep, currentSubStep, changeSubStep } = useOffer()
  const { formId, formFields } = onboardingFormModel
  const [initialFields, setInitialFields] = useState([])
  const [onboardingSchema, setOnboardingSchema] = useState({})
  const [isLoadingInitialFields, setIsLoadingInitialFields] = useState(true)
  const [showSuccess, setShowSuccess] = useState(false)

  let codeInputs = null

  const handleOnChangeCodeInputs = (e) => {
    if (/[0-9]/g.test(e.target.value)) {
      codeInputs[e.target.index + 1].focus()
    }
  }

  useMemo(() => {
    let initialSchema = {}
    const initFields = Object.keys(formFields).reduce((acc, key) => {
      const stepModel = formFields[key]
      for (let i = 0; i < stepModel.length; i++) {
        if (key === `step_${currentStep}` || key === `step_${currentStep}_${currentSubStep}`) {
          const field = stepModel[i]
          acc[field.name] = field.initialValue
          initialSchema[field.name] = field.validationSchema
        }
      }
      return acc
    }, {})
    setInitialFields(initFields)
    setOnboardingSchema(yup.object().shape(initialSchema))
    setIsLoadingInitialFields(false)
  }, [currentStep, currentSubStep, formFields])

  /**
   * Code form
   * Go automatically to the next input
   */
  useEffect(() => {
    if (currentSubStep === 1 && !codeInputs) {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      codeInputs = Array.from(document.querySelectorAll('input[name^="code_number"'))

      codeInputs.slice(0, 3).forEach((input, i) => {
        input.index = i
        input.addEventListener('keyup', handleOnChangeCodeInputs, false)
      })
    }
  }, [currentSubStep])

  const renderStepContent = (errors, touched) => {
    switch (currentStep) {
      case 0:
        return (
          <Landing
            selectedOffer={selectedOffer}
            changeStep={changeStep}
            changeSubStep={changeSubStep}
          />
        )
      case 1: {
        return (
          <Profile
            key={`step_1_${currentSubStep}`}
            formFields={formFields[`step_1_${currentSubStep}`]}
            changeStep={changeStep}
            changeSubStep={changeSubStep}
            title={PROFILE_STEP_SETTINGS[`step_1_${currentSubStep}`]?.title}
            image={
              `step_1_${currentSubStep}` === 'step_1_0'
                ? {
                    url: franceConnectImage,
                    alt: 'France Connect'
                  }
                : null
            }
            description={PROFILE_STEP_SETTINGS[`step_1_${currentSubStep}`]?.description}
            errors={errors}
            touched={touched}
          />
        )
      }
      case 2:
        return <Recap />
      case 3:
        return renderForm(formFields.step_3, errors, touched)
      case 4:
        return <SignerContart />
      case 5:
        return <>{showSuccess === false && renderForm(formFields.step_5, errors, touched)}</>
      case 6:
        return <Success />
      default:
        return <div>Not Found</div>
    }
  }

  const handleSubmit = (values) => {
    console.log('submit')
    console.log(values)

    /**
     * Code form
     * Remove all event listeners before go to the next form
     */
    if (currentSubStep === 1) {
      codeInputs.slice(0, 3).forEach((input) => {
        input.removeEventListener('keyup', handleOnChangeCodeInputs, false)
      })
    }

    if (currentStep === 1 && currentSubStep < 5) {
      changeSubStep(currentSubStep + 1)
    } else if (currentStep === 5) {
      changeStep(currentStep + 1)
      setShowSuccess(true)
    } else {
      changeStep(currentStep + 1)
    }
  }

  return (
    <SubscriptionSectionWrapper>
      <Header showNav={false} />
      <ContentWrapper className="container">
        <Tracking
          showSuccess={showSuccess}
          currentStep={currentStep}
          currentSubStep={currentSubStep}
        />
        <div className="form-container">
          {isLoadingInitialFields ? (
            <p>Loading...</p>
          ) : (
            <Formik
              enableReinitialize
              initialValues={initialFields}
              validationSchema={onboardingSchema}
              onSubmit={handleSubmit}
            >
              {({ isSubmitting, errors, touched }) => (
                <Form
                  id={formId}
                  className={`step_${currentStep}${currentStep === 1 ? '_' + currentSubStep : ''}`}
                >
                  <div>
                    <h1 className="form-title"> {STEP_TITLES[currentStep]}</h1>
                    {currentStep === 1 && (
                      <ProgressBar
                        completed={PROFILE_STEP_SETTINGS[`step_1_${currentSubStep}`]?.progressValue}
                        bgcolor="#ee2464"
                      />
                    )}
                  </div>
                  {renderStepContent(errors, touched)}

                  {!showSuccess && (
                    <div className="actions-wrapper">
                      <div className="action">
                        {currentStep > 0 &&
                          currentStep !== 2 &&
                          currentSubStep !== 1 &&
                          currentStep !== 4 && (
                            <div>
                              {isSubmitting ? (
                                <Loader scale={0.5} />
                              ) : (
                                <button type="submit" className="cta" disabled={isSubmitting}>
                                  {currentStep === '5' ? 'Je valide' : 'Continuer'}
                                </button>
                              )}
                            </div>
                          )}
                      </div>
                    </div>
                  )}

                  {
                    /* Resend SMS button in sub step 1 */
                    currentStep === 1 && currentSubStep === 1 && (
                      <div className="actions-wrapper">
                        <div className="action">
                          <div>
                            <button type="submit" className="cta BtnValide" disabled={isSubmitting}>
                              Valider
                            </button>
                          </div>
                          <div>
                            <button type="submit" className="cta btnSms">
                              Recevoir à nouveau le sms
                            </button>
                          </div>
                        </div>
                      </div>
                    )
                  }
                </Form>
              )}
            </Formik>
          )}
        </div>
      </ContentWrapper>
      <Outlet />
    </SubscriptionSectionWrapper>
  )
}

export default Subscription
