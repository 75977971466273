import { createGlobalStyle } from 'styled-components/macro'
import { devices } from './defaultTheme'

import TrendaWoff from "../assets/fonts/Trenda-Regular.woff";
import TrendaWoff2 from "../assets/fonts/Trenda-Regular.woff2";
import TrendaSemiBoldWoff from "../assets/fonts/Trenda-Semibold.woff";
import TrendaSemiBoldWoff2 from "../assets/fonts/Trenda-Semibold.woff2";
import TrendaBoldWoff from "../assets/fonts/Trenda-Bold.woff";
import TrendaBoldWoff2 from "../assets/fonts/Trenda-Bold.woff2";


const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'Trenda';
    font-style: normal;
    font-weight: 400;
    src: url(${TrendaWoff2}) format('woff2'),
        url(${TrendaWoff}) format('woff');
  }
  @font-face {
    font-family: 'Trenda';
    font-style: normal;
    font-weight: 600;
    src: url(${TrendaSemiBoldWoff2}) format('woff2'),
        url(${TrendaSemiBoldWoff}) format('woff');
  }
  @font-face {
    font-family: 'Trenda';
    font-style: normal;
    font-weight: 700;
    src: url(${TrendaBoldWoff2}) format('woff2'),
        url(${TrendaBoldWoff}) format('woff');
  }

	html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  b, u, i, center,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed,
  figure, figcaption, footer, header, main, hgroup,
  menu, nav, output, ruby, section, summary,
  time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
  }

  * {
    box-sizing: border-box;
  }

  html {
    height: 100%;
    font-size: 62.5%; /* 1rem = 10px */
  }

  body {
    height: 100%;
    padding: 0;
    margin: 0;
    background-image: none;
    font-family: ${(props) => props.theme.fonts.fontFamily1};
    color: ${(props) => props.theme.colors.primaryColor};
    font-size: 16px;
  }

  body:has(#landingForm) {
    @media (max-width: 767px) {
      background-color: #44bde5;
    }

    @media (min-width: 768px) {
      background-image: url(/images/landing-bg.jpg);
      background-attachment: fixed;
      background-position: center bottom;
      background-repeat: no-repeat;
      background-size: cover;
    }
  }
  
  #root {
    height: 100%;
  }

  .container {
    width: 100%;
    margin: 0 auto;
    padding: 0 12px;
    &.pageTop {
      padding-top: 2rem;
    }
    @media ${devices.tablet} { 
      max-width: 1024px;
      &.pageTop {
        padding-top: 7rem;
      }
    }
  }

  a {
    text-decoration: none;
    color: ${(props) => props.theme.colors.primaryColor};
    &.active,
    &:hover {
      color: ${(props) => props.theme.colors.secondaryColor};
    }
  }

  a, button {
    border: none;
    background: none;
    box-shadow: none;
    font-family: ${(props) => props.theme.fonts.fontFamily1};
    &:hover {
      cursor: pointer;
    }
    &.cta {
      &:is(a){
        display: inline-block !important;
        line-height: 4.5rem;
      }
      position: relative;
      z-index: 1;
      overflow: hidden;
      padding: 0 4.5rem;
      height: 4.5rem;
      vertical-align: middle;
      border-radius: 2.4rem;
      text-align: center;
      color: ${(props) => props.theme.colors.primaryColor};
      border: 1px solid ${(props) => props.theme.colors.secondaryColor};
      font-size: 1.6rem;
      font-weight: 600;
      transition: color 0.2s ease-in-out;
      margin-bottom: 6rem;
      :before {
        content: '';
        display: block;
        position: absolute;
        z-index: -1;
      }
      &:after {
        content: '';
        display: block;
        position: absolute;
        z-index: -1;
        top: 0;
        right: 0;
        bottom: auto;
        left: 0;
        height: 300%;
        width: 100%;
        background: ${(props) => props.theme.colors.secondaryColor};
        transition: height 0.3s ease-in-out;
      }
      &:hover {
        color: ${(props) => props.theme.colors.secondaryColor};
        &:after {
          height: 0;
        }
      }
            
    }
  }

  .mobile-only {
    display: block;
    @media (min-width: 1024px) {
      display: none;
    }
  }

  .desktop-only {
    display: none;
    @media (min-width: 1024px) {
      display: block;
    }
  }

  .highcharts-label.highcharts-no-data {
    color: ${(props) => props.theme.colors.secondaryColor} !important;
    opacity: 0.4;
    font-family: ${(props) => props.theme.fonts.fontFamily1};
  }

  .highcharts-label.highcharts-no-data text {
    color: ${(props) => props.theme.colors.secondaryColor} !important;
    font-family: ${(props) => props.theme.fonts.fontFamily1};
    font-weight: 300 !important;
  }
`
export default GlobalStyle