import { createContext, useContext, useState } from 'react'

const OfferContext = createContext()

const OfferProvider = ({ children }) => {
  const localSelectedOffer = localStorage.getItem('selectedOffer') || ''
  const localCurrentStep = localStorage.getItem('currentStep')
  const localCurrentSubStep = localStorage.getItem('currentSubStep')

  const initialCurrentStep = localCurrentStep ? parseInt(localCurrentStep) : 0
  const initialCurrentSubStep = localCurrentSubStep ? parseInt(localCurrentSubStep) : 0

  const [selectedOffer, setSelectedOffer] = useState(localSelectedOffer || '')
  const [currentStep, setCurrentStep] = useState(initialCurrentStep)
  const [currentSubStep, setCurrentSubStep] = useState(initialCurrentSubStep)

  const chooseOffer = (offerId) => {
    localStorage.setItem('selectedOffer', offerId)
    localStorage.setItem('currentStep', '0')
    setSelectedOffer(offerId)
    setCurrentStep(0)
    setCurrentSubStep(0)
  }

  const resetOffer = () => {
    localStorage.setItem('selectedOffer', '')
    localStorage.setItem('currentStep', '0')
    setSelectedOffer('')
    setCurrentStep(0)
    setCurrentSubStep(0)
  }

  const changeStep = (step) => {
    localStorage.setItem('currentStep', step.toString())
    setCurrentStep(step)
    setCurrentSubStep(0)
  }

  const changeSubStep = (subStep) => {
    localStorage.setItem('currentSubStep', subStep.toString())
    setCurrentSubStep(subStep)
  }

  const value = {
    selectedOffer,
    chooseOffer,
    resetOffer,
    currentStep,
    changeStep,
    currentSubStep,
    changeSubStep
  }

  return (
    <OfferContext.Provider value={value}>
      {children}
    </OfferContext.Provider>
  )
}

export default OfferProvider

export const useOffer = () => useContext(OfferContext)
