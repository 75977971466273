import { Link } from 'react-router-dom'
import styled from 'styled-components/macro'

const FooterWrapper = styled.div`
  .footer {
    margin: 0 auto;
    color: #dbdbdb;
    height: 321px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    position: absolute;
    width: 100%;
    left: 0;

    &:before {
      content: ' ';
      position: absolute;
      left: 0;
      width: 100vw;
      height: inherit;
      background-color: #014751;
      z-index: -1;
    }

    h4 {
      font-size: 16px;
      font-weight: bold;
      color: #fff;
      padding: 1rem 0;
    }
  }

  li {
    list-style-type: none;
    padding: 0.5rem 0;
    a {
      color: #dbdbdb;
    }
  }

  .foot-col-4 {
    margin-bottom: 6.5rem;
  }
  .footer::after {
    content: '';
    position: absolute;
    top: 4px;
    left: -15px;
    background: red;
  }

  @media (max-width: 600px) {
    .footer {
      display: grid;
      grid-template-columns: repeat(2, 1fr);

    }
    .col{
      margin-left: 2rem
    }
  
  }
`

const Footer = () => {
  return (
    <>
      <FooterWrapper>
        <div className="footer">
          <div className="foot-col-1 col">
            <h4>Nos offres</h4>
            <ul>
              <li>
                <Link to="#">Offer 01</Link>
              </li>
              <li>
                <Link to="#">Offer 02</Link>
              </li>
              <li>
                <Link to="#">Offer 03</Link>
              </li>
            </ul>
          </div>

          <div className="foot-col-2 col">
            <h4>Tarifs</h4>
            <ul>
              <li>
                <Link to="#">A propos</Link>
              </li>
              <li>
                <Link to="#">Contact</Link>
              </li>
              <li>
                <Link to="#">Mentions légales</Link>
              </li>
            </ul>
          </div>

          <div className="foot-col-3 col">
            <h4>Ressources</h4>
            <ul>
              <li>
                <Link to="#">FAQ</Link>
              </li>
              <li>
                <Link to="#">Blog</Link>
              </li>
              <li>
                <Link to="#">Guide</Link>
              </li>
            </ul>
          </div>

          <div className="foot-col-4 col">
            <h4>Retrouvez nous</h4>
            <p>www.onboarding.com</p>
          </div>
        </div>
      </FooterWrapper>
    </>
  )
}
export default Footer
