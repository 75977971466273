import styled from 'styled-components/macro'

import dematerialiser from '../assets/images/icons/dematerialiser.svg'
import distrib from '../assets/images/icons/distrib.svg'
import experience from '../assets/images/icons/experience.svg'
import ioscolor from '../assets/images/icons/ios-color-palette.svg'

const AvantageWrapper = styled.div`
.avantageClient {
  margin: 6rem 0;
  height: 349px;
}

.avantageClient:before {
  content: " ";
  position: absolute;
  left: 0;
  width: 100vw;
  height: inherit;
  background-color: rgba(46, 216, 195, 0.05);
  z-index: -1;
}


h1 {
  font-size: 3rem;
  font-weight: bold;
  line-height: 4.33;
  letter-spacing: 0.12px;
  text-align: center;
  color: #014751;
  padding: 1rem 0;
}
.blocks-avantages {
  display: flex;
  justify-content: space-around;
}

.icon-avantge {
  position:relative;
  top:0;
  left:45%;
  padding: 2rem 0;
}
.avantage > p {
  text-align: center;
  font-size: 16px;
  padding: 0 2rem;
}

@media (max-width: 625px){
  h1 {
    font-size: 2rem;
    line-height: 2.33;
  }
  .blocks-avantages {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 5px;
  }
  .avantage > p {
    font-size: 1.3rem;
  }
}

@media (max-width: 380px){
  .avantageClient {
    height: 38rem;
  }
  h1 {
    font-size: 1.9rem;
    line-height: 1.9;
  }
  .avantage > p {
    font-size: 1.1rem;
}

`

const AvantageClient = () => {
  const avantages = [
    {
      id: 1,
      text: 'Rechargez votre portefeuille électronique par simple virement bancaire',
      iconUrl: dematerialiser,
    },
    {
      id: 2,
      text: 'Ajoutez votre logo ou personnalisez entièrement vos cartes',
      iconUrl: ioscolor,
    },
    {
      id: 3,
      text: 'Un espace Manager via lequel vous pouvez créer de nouvelle carte',
      iconUrl: experience,
    },
    {
      id: 4,
      text: 'Distribuer vos dotations en un clic et suivre votre parc en temps réel.',
      iconUrl: distrib,
    },
  ]
  return (
    <>
      <AvantageWrapper>
        <div className="avantageClient">
          <h1> Les avantages de devenir client […] </h1>
          <div className="blocks-avantages">
            {avantages.map((avantage) => {
              return (
                <div className="avantage" key={avantage.id}>
                  <img
                    className="icon-avantge"
                    src={avantage.iconUrl}
                    alt="icons"
                  />
                  <p>{avantage.text}</p>
                </div>
              )
            })}
          </div>
        </div>
      </AvantageWrapper>
    </>
  )
}
export default AvantageClient
