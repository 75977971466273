import * as yup from 'yup'
import nationalitiesJSON from '../../assets/data/nationalities.json'

const nationalities = Object.keys(nationalitiesJSON).map((k) => ({ label: nationalitiesJSON[k], value: k }))

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

const numberRegExp = /[0-9]/g

const model = {
  formId: 'onboardingForm',
  formFields: {
    step_1_0: [
      {
        type: 'section',
        label: 'Civilité*'
      },
      {
        name: 'civility',
        type: 'radio',
        label: 'civilité',
        initialValue: '',
        validationSchema: yup.string().required("Vous n'avez rien sélectionné"),
        options: [
          {
            label: 'Homme',
            value: '1'
          },
          {
            label: 'Femme',
            value: '2'
          }
        ]
      },
      {
        name: 'lastName',
        type: 'text',
        mandatory: true,
        label: 'Nom',
        initialValue: '',
        validationSchema: yup.string().required('Ce champ est invalide')
      },
      {
        name: 'firstName',
        type: 'text',
        label: 'Prénom',
        mandatory: true,
        initialValue: '',
        validationSchema: yup.string().required('Ce champ est invalide')
      },
      {
        name: 'phone_prefix',
        type: 'text',
        label: 'Préfixe',
        width: 1,
        group: 'phone',
        placeholder: 'Ex: +33',
        initialValue: '',
        validationSchema: yup.string().required('Ce champ est invalide')
      },
      {
        name: 'phone',
        type: 'phone',
        label: 'Téléphone',
        mandatory: true,
        group: 'phone',
        initialValue: '',
        validationSchema: yup
          .string()
          .matches(phoneRegExp, 'Veuillez renseigner un numéro de téléphone valide')
          .required('Ce champ est invalide')
      },
      {
        name: 'email',
        type: 'email',
        label: 'E-mail',
        mandatory: true,
        initialValue: '',
        validationSchema: yup
          .string()
          .email('Veuillez renseigner une adresse mail valide')
          .required('Ce champ est invalide')
      },
      {
        name: 'nationality',
        type: 'select',
        label: 'Nationalité',
        initialValue: nationalities[0].value,
        validationSchema: yup.string().required("Vous n'avez rien sélectionné"),
        options: nationalities
      },
      {
        name: 'birthPlace',
        type: 'autocomplete',
        label: 'Ville de naissance',
        initialValue: '',
        mandatory: true
      }
    ],
    step_1_1: [
      {
        name: 'code_number_1',
        type: 'text',
        hideLabel: true,
        maxLength: 1,
        classes: 'substep-big-number',
        initialValue: '',
        validationSchema: yup
          .string()
          .matches(numberRegExp, 'Doit être un chiffre').required('Doit être un chiffre')
      },
      {
        name: 'code_number_2',
        type: 'text',
        hideLabel: true,
        maxLength: 1,
        classes: 'substep-big-number',
        initialValue: '',
        validationSchema: yup
          .string()
          .matches(numberRegExp, 'Doit être un chiffre').required('Doit être un chiffre')
      },
      {
        name: 'code_number_3',
        type: 'text',
        hideLabel: true,
        maxLength: 1,
        classes: 'substep-big-number',
        initialValue: '',
        validationSchema: yup
          .string()
          .matches(numberRegExp, 'Doit être un chiffre').required('Doit être un chiffre')
      },
      {
        name: 'code_number_4',
        type: 'text',
        hideLabel: true,
        maxLength: 1,
        classes: 'substep-big-number',
        initialValue: '',
        validationSchema: yup
          .string()
          .matches(numberRegExp, 'Doit être un chiffre').required('Doit être un chiffre')
      }
    ],
    step_1_2: [
      {
        name: 'onboardingReason',
        type: 'checkbox',
        hideLabel: true,
        initialValue: [],
        validationSchema: yup.array().min(1, "Vous n'avez rien sélectionné"),
        options: [
          {
            label: 'Pour mes enfants',
            value: '2'
          },
          {
            label: 'Souscrire à un crédit',
            value: '3'
          },
          {
            label: 'Pour épargner',
            value: '4'
          }
        ]
      }
    ],
    step_1_3: [
      {
        name: 'address',
        type: 'text',
        label: 'Adresse',
        mandatory: true,
        initialValue: '',
        validationSchema: yup.string().required('Ce champ est invalide')
      },
      {
        name: 'address_code',
        type: 'text',
        label: 'Numéro de rue',
        mandatory: true,
        initialValue: '',
        validationSchema: yup.string().required('Ce champ est invalide')
      },
      {
        name: 'zip',
        type: 'text',
        label: 'Code postal',
        mandatory: true,
        initialValue: '',
        validationSchema: yup
          .string()
          .matches(numberRegExp, 'Ce champ est invalide').required('Ce champ est invalide')
      },
      {
        name: 'city',
        type: 'text',
        label: 'Ville',
        mandatory: true,
        initialValue: '',
        validationSchema: yup.string().required('Ce champ est invalide')
      },
      {
        name: 'city_tax',
        type: 'select',
        label: 'Où déclarez-vous vos impôts ?',
        initialValue: '1',
        options: [
          {
            label: 'France',
            value: '1'
          },
          {
            label: 'Allemagne',
            value: '2'
          },
          {
            label: 'Italie',
            value: '3'
          },
          {
            label: 'Espagne',
            value: '4'
          }
        ]
      }
    ],
    step_1_4: [
      {
        type: 'section',
        label: 'Que faites-vous dans la vie ?*'
      },
      {
        name: 'life_situation',
        type: 'radio',
        initialValue: '',
        validationSchema: yup.string().required("Vous n'avez rien sélectionné"),
        options: [
          {
            label: 'Salarié(e)',
            value: '1'
          },
          {
            label: 'Non-salarié(e)',
            value: '2'
          },
          {
            label: 'Etudiant',
            value: '3'
          },
          {
            label: 'Retraité(e)',
            value: '4'
          },
          {
            label: 'Sans emploi',
            value: '5'
          }
        ]
      },
      {
        type: 'section',
        label: 'Quelle est votre catégorie professionelle ?*'
      },
      {
        name: 'profesionnal_situation',
        type: 'radio',
        initialValue: '',
        validationSchema: yup.string().required("Vous n'avez rien sélectionné"),
        options: [
          {
            label: 'Salarié(e)',
            value: '1'
          },
          {
            label: 'Employé(e)',
            value: '2'
          },
          {
            label: 'Ouvrier(ère)',
            value: '3'
          },
          {
            label: 'Profession intermédiaire',
            value: '4'
          }
        ]
      },
      {
        name: 'city_tax',
        type: 'select',
        label: 'Quelle est votre sous-catégorie professionelle ?',
        initialValue: '1',
        options: [
          {
            label: 'Cadre technique',
            value: '1'
          },
          {
            label: 'Autre',
            value: '2'
          }
        ]
      },
      {
        name: 'incomes',
        type: 'text',
        label: 'Quels sont vos revenus ?',
        mandatory: true,
        initialValue: '',
        validationSchema: yup
          .string()
          .matches(numberRegExp, 'Ce champ est invalide').required('Ce champ est invalide')
      },
      {
        type: 'section',
        label: 'Quel type de contrat de travail avez-vous ?'
      },
      {
        name: 'contract_type',
        type: 'select',
        initialValue: '1',
        options: [
          {
            label: 'CDI',
            value: '1'
          },
          {
            label: 'CDD',
            value: '2'
          }
        ]
      },
      {
        name: 'contract_start_date',
        type: 'datepicker',
        label: 'Date de début de contrat',
        mandatory: true,
        initialValue: ''
      }
    ],
    step_1_5: [
      {
        type: 'section',
        label: 'Quelle est votre situation ?*'
      },
      {
        name: 'social_situation',
        type: 'radio',
        initialValue: '',
        validationSchema: yup.string().required("Vous n'avez rien sélectionné"),
        options: [
          {
            label: 'Célibataire',
            value: '1'
          },
          {
            label: 'Marié(e)',
            value: '2'
          },
          {
            label: 'Divorcé(e)',
            value: '3'
          },
          {
            label: 'Veuf(ve)',
            value: '3'
          },
          {
            label: 'En comcubinage',
            value: '4'
          },
          {
            label: 'Pacsé(e)',
            value: '5'
          }
        ]
      },
      {
        name: 'dependent_children',
        type: 'select',
        label: 'Combien d’enfants avez-vous à charge ?',
        initialValue: '0',
        options: [
          {
            label: '0',
            value: '0'
          },
          {
            label: '1',
            value: '1'
          },
          {
            label: '2',
            value: '2'
          },
          {
            label: '3',
            value: '3'
          },
          {
            label: '4',
            value: '4'
          },
          {
            label: '5+',
            value: '5+'
          }
        ]
      },
      {
        type: 'section',
        label: 'Quelle est votre situation de logement ?*'
      },
      {
        name: 'lodging_situation',
        type: 'radio',
        initialValue: '',
        validationSchema: yup.string().required("Vous n'avez rien sélectionné"),
        options: [
          {
            label: 'Propriétaire avec prêt',
            value: '1'
          },
          {
            label: 'Propriétaire sans prêt',
            value: '2'
          },
          {
            label: 'Locataire',
            value: '3'
          },
          {
            label: 'Logement de fonction',
            value: '4'
          },
          {
            label: 'Logé(e) à titre gratuit',
            value: '5'
          },
          {
            label: 'Autre',
            value: '6'
          }
        ]
      },
      {
        type: 'section',
        label: 'Depuis quand habitez-vous dans votre logement ?'
      },
      {
        name: 'house_entry_date',
        type: 'datepicker',
        placeholder: "Date d'entrée",
        initialValue: ''
      }
    ],
    step_3: [
      {
        type: 'section',
        label: 'Comment souhaitez-vous être prélevé ?*'
      },
      {
        name: 'payment_period',
        type: 'radio',
        initialValue: '',
        validationSchema: yup.string().required("Vous n'avez rien sélectionné"),
        options: [
          {
            label: 'Mensuel',
            value: '1'
          },
          {
            label: 'Annuel',
            value: '2'
          }
        ]
      },
      {
        type: 'section',
        label: 'Quel type de versement souhaitez-vous ?*'
      },
      {
        name: 'payment_type',
        type: 'radio',
        initialValue: '',
        validationSchema: yup.string().required("Vous n'avez rien sélectionné"),
        options: [
          {
            label: 'Prélèvement',
            value: '1'
          },
          {
            label: 'Carte bancaire',
            value: '2'
          }
        ]
      }
    ],
    step_5: [
      {
        name: 'cni',
        type: 'file',
        label: "Pièce d'identité",
        initialValue: ''
      },
      {
        name: 'rib',
        type: 'file',
        label: 'RIB',
        initialValue: '',
      },
      {
        name: 'proof_of_address',
        type: 'file',
        label: 'Justificatif de domicile',
        initialValue: '',
      },
      {
        name: 'proof_of_ressources',
        type: 'file',
        label: 'Justificatif de revenus',
        initialValue: '',
      },
      {
        name: 'agree_1',
        type: 'checkbox',
        classes: 'is-simple',
        hideLabel: true,
        initialValue: [],
        options: [
          {
            label: 'Je reconnais avoir pris connaissance et accepte les conditions générales',
            value: '1'
          }
        ],
        validationSchema: yup.array().min(1, 'Ce champ est obligatoire')
      },
      {
        name: 'agree_2',
        type: 'checkbox',
        classes: 'is-simple',
        hideLabel: true,
        initialValue: [],
        options: [
          {
            label: 'J’accepte de transmettre mes données personnelles à Banque 2000',
            value: '1'
          }
        ],
        validationSchema: yup.array().min(1, 'Ce champ est obligatoire')
      }
    ]
  }
}

export default model
