import React from 'react'
import styled from 'styled-components'
import choix from '../assets/images/icons/choix.svg'
import documents from '../assets/images/icons/documents.svg'
import infos from '../assets/images/icons/infos.svg'
import prelevement from '../assets/images/icons/prelevement.svg'
import recap from '../assets/images/icons/recap.svg'
import signature from '../assets/images/icons/signature.svg'

const TrackingList = styled.ol`
  margin-right: 7rem;
  max-width: 20rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  list-style: none;
  height: calc(100% - 3rem);

  .tracking-item {
    position: relative;
    flex: 1;
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;

    &:after {
      content: '';
      position: absolute;
      top: 30px;
      left: 14px;
      height: calc(100% - 32px);
      width: 2px;
      background-image: linear-gradient(
        0deg,
        ${(props) => props.theme.colors.primaryColor},
        ${(props) => props.theme.colors.primaryColor} 75%,
        transparent 75%,
        transparent 100%
      );
      background-size: 1px 10px;
      opacity: 0.3;
    }

    &:last-of-type {
      &::after {
        display: none;
      }
    }

    > img {
      width: 30px;
      margin-right: 7px;
      opacity: 0.3;
    }

    > span {
      display: block;
      padding-top: 5px;
      font-size: 1.6rem;
      font-weight: bold;
      opacity: 0.3;
    }

    &.done {
      > img,
      > span,
      &:after {
        opacity: 1;
      }
    }
  }

  .tracking-item-sublist {
    flex: 0 0 100%;
    padding-left: 37px;
    list-style: none;

    > li {
      font-size: 1.2rem;
      font-weight: 700;
      opacity: 0.3;

      &.done {
        opacity: 1;
      }
    }
  }

  @media (max-width: 768px){
    margin-right: 0;
  .tracking-item > span {
    display: none;
  }
  .tracking-item-sublist > li {
    display: none;
  }
}

`

const Tracking = ({ showSuccess, currentStep, currentSubStep }) => {
  if (showSuccess) return null

  return (
    <TrackingList>
      <li className="tracking-item done">
        <img src={choix} alt="icon" />
        <span>Choix de l'offre</span>
      </li>
      <li className={`tracking-item ${currentStep > 0 && 'done'}`}>
        <img src={infos} alt="icon" />
        <span>Vos informations</span>
        <ol className="tracking-item-sublist">
          <li className={`${(currentStep > 1 || (currentStep === 1 && currentSubStep >= 0)) && 'done'}`}>1- Identité</li>
          <li className={`${(currentStep > 1 || (currentStep === 1 && currentSubStep >= 1)) && 'done'}`}>2- Code</li>
          <li className={`${(currentStep > 1  || (currentStep === 1  && currentSubStep >= 2)) && 'done'}`}>3- Destinataire de l'offre</li>
          <li className={`${(currentStep > 1 || (currentStep === 1 && currentSubStep >= 3)) && 'done'}`}>4- Adresse</li>
          <li className={`${(currentStep > 1  || (currentStep === 1 && currentSubStep >= 4)) && 'done'}`}>5- Situation professionnelle</li>
          <li className={`${(currentStep > 1  || (currentStep === 1 && currentSubStep >= 5)) && 'done'}`}>6- Situation familiale</li>

        </ol>
      </li>
      <li className={`tracking-item ${currentStep > 1 && 'done'}`}>
        <img src={recap} alt="icon" />
        <span>Récapitulatif</span>
      </li>
      <li className={`tracking-item ${currentStep > 2 && 'done'}`}>
        <img src={prelevement} alt="icon" />
        <span>Prélèvement</span>
      </li>
      <li className={`tracking-item ${currentStep > 3 && 'done'}`}>
        <img src={signature} alt="icon" />
        <span>Signature</span>
      </li>
      <li className={`tracking-item ${currentStep > 4 && 'done'}`}>
        <img src={documents} alt="icon" />
        <span>Documents</span>
      </li>
    </TrackingList>
  )
}

export default Tracking
