import React from 'react'
import styled from 'styled-components'

import check from '../assets/images/icons/check.svg'

const FormWrapper = styled.form`
  .votre-identit {
    margin: 0 142px 29px;
    text-align: center;
    font-size: 16px;
    color: #014751;
  }

  /**** Input empty *******/

  .input-empty {
    border: solid 1px #919a9c;
  }

  .label-empty {
    color: #919a9c;
  }

  /**** Input error *******/

  .input-error {
    border: solid 1px #b70000;
    background-color: rgba(183, 0, 0, 0.05);
  }

  .label-error {
    color: #b70000;
  }

  /**** MessageError ***********************/

  .message-error {
    margin: 0px 319px 0 0;
    font-size: 10px;
    letter-spacing: 0.04px;
    color: #b70000;
  }

  /******* checkbox ***********************/

  .blocks-checkboxs {
    position: relative;
    width: 408px;
    height: 68px;
  }

  input[type='checkbox'] + .label-check::after {
    background: none;
  }

  input[type='checkbox'] + .label-check {
    color: #014751;
    width: 187px;
    height: 49px;
    border: solid 1px #014751;
    transition: none;
  }

  input[type='checkbox']:checked + .label-check {
    color: #0964aa;
    width: 187px;
    height: 49px;
    border: solid 1px #2ed8c3;
    background-image: url(${check});
    background-repeat: no-repeat;
    background-position: 93% 41%;
    background-size: 20px;
  }

  .gender {
    font-size: 15px;
    padding: 10px 10px;
    line-height: 1.86;
    color: #014751;
  }

  input[type='checkbox'] {
    visibility: hidden;
  }

  .mgcheck-2 {
    margin-left: 202px;
  }

  /******* Radio      **********************/

  .radios {
    width: 187px;
    height: 49px;
    position: relative;
    right: 111px;
    bottom: 10px;
  }

  input[type='radio'] + .label-radio::after {
    background: none;
  }

  input[type='radio'] + .label-radio {
    color: #014751;
    width: 187px;
    height: 49px;
    border: solid 1px #014751;
    transition: none;
  }

  input[type='radio']:checked + .label-radio {
    color: #0964aa;
    width: 187px;
    height: 49px;
    border: solid 1px #2ed8c3;
    background-image: url(${check});
    background-repeat: no-repeat;
    background-position: 93% 41%;
    background-size: 20px;
  }

  input[type='radio'] {
    visibility: hidden;
  }

  .gender-radio {
    font-size: 15px;
    padding: 10px 10px;
    line-height: 1.86;
    color: #014751;
  }

  .mgradio-2 {
    margin-left: 202px;
  }

  /**********************Input******************/

  .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100vw;
    position: absolute;
    top: 14%;
  }
  

  .box {
    position: relative;
    border-radius: 10px;
  }

  input {
    transition: all 0.2s;
    touch-action: manipulation;
    width: 388px;
    height: 49px;
    margin: 15px 0;
    padding: 0 10px;
    border: solid 1px #014751;
    background-color: #fff;
    cursor: pointer;
  }

  label {
    position: absolute;
    top: 38px;
    left: 12px;
    color: black;
    font-size: 11px !important;
    transition: all 0.2s;
    color: #014751;
  }

  input::placeholder {
    opacity: 0;
    color: black;
    font-size: 16px !important;
    color: #014751;
  }

  input:focus::placeholder {
    display: none;
  }

  input:focus {
    outline: none;
  }

  input:placeholder-shown + label {
    cursor: pointer;
    max-width: 66.66%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-transform-origin: left bottom;
    -moz-transform-origin: left bottom;
    -ms-transform-origin: left bottom;
    -o-transform-origin: left bottom;
    -webkit-transform: translate(0, 0) scale(1.5);
    -moz-transform: translate(0, 0) scale(1.5);
    -ms-transform: translate(0, 0) scale(1.5);
    -o-transform: translate(0, 0) scale(1.5);
  }

  input:focus + label,
  input:not(:placeholder-shown) + label {
    -webkit-transform: translate(-2px, -30px) scale(1);
    -moz-transform: translate(2px, -30px) scale(1);
    -o-transform: translate(2px, -30px) scale(1);
    -ms-transform: translate(2px, -30px) scale(1);
    padding: 0 3px;
  }

  input:focus + label::after,
  input + label::after {
    content: ' ';
    display: block;
    position: absolute;
    background: #fff;
    height: 3px;
    top: 50%;
    left: -0.2em;
    right: -0.2em;
    z-index: -1;
  }

  input:not(:placeholder-shown) + label::after {
    left: -0.4em;
    right: -0.4em;
  }

  /*************** select pays *****************/

  .pays {
    width: 388px;
    height: 49px;
    margin: 15px 0;
    padding: 0 10px;
    border: solid 1px #014751;
    background-color: #fff;
    cursor: pointer;
    font-size: 15px;
    color: #014751;
  }
  .pays:focus {
    outline: none;
  }

  /**** Button submit *******/

  .btn {
    margin: 30px 0;
    padding: 11px 44px 12px;
    border-radius: 21px;
    background-color: #2ed8c3;
  }
`
const SignupForm = () => {
  const error = 'Erreur de saisie'
  return (
    <>
      <FormWrapper>
        <div className="container">
          <p className="votre-identit"> Votre identité </p>
          <div className="blocks-checkboxs">
            <div className="check-1">
              <input type="checkbox" id="homme" name="homme" />
              <label className="label-check" htmlFor="homme">
                <p className="gender">Homme </p>
              </label>
            </div>
            <input type="checkbox" id="femme" name="femme" />
            <label className="label-check mgcheck-2" htmlFor="femme">
              <p className="gender">Femme </p>
            </label>
          </div>
          <div className="radios">
            <div className="radio">
              <input type="radio" id="man" name="drone" value="homme" checked />
              <label htmlFor="man" className="label-radio">
                {' '}
                <p className="gender-radio">Homme </p>
              </label>
            </div>
            <div className="radio">
              <input
                type="radio"
                id="woman"
                name="drone"
                value="femme"
                checked
              />
              <label htmlFor="woman" className="label-radio mgradio-2">
                {' '}
                <p className="gender-radio">Femme</p>
              </label>
            </div>
          </div>
          <div className="box">
            <input
              className="input"
              name="firstname"
              type="text"
              placeholder="Prénom"
            />
            <label htmlFor="firstName">Prénom</label>
          </div>
          <span>
            <p className="message-error">{error}</p>
          </span>

          <div className="box">
            <input
              id="lastName"
              className="input-empty"
              name="lastName"
              type="text"
              placeholder="Nom"
            />
            <label className="label-empty" htmlFor="lastName">
              Nom
            </label>
          </div>

          <div className="box">
            <input
              className="input-error"
              name="tel"
              type="tel"
              placeholder="Tel"
            />
            <label className="label-error" htmlFor="tel">
              Tel
            </label>
          </div>
          <span>
            <p className="message-error">{error}</p>
          </span>

          <div className="box">
            <input id="email" name="email" type="email" placeholder="Email" />
            <label htmlFor="email">Email</label>
          </div>

          <div className="box">
            <select className="pays" id="pays" name="pays">
              <optgroup label="Europe">
                <option value="">France</option>
                <option value="">allemagne</option>
                <option value="">andorre</option>
                <option value="">aruba</option>
                <option value="">autriche</option>
              </optgroup>
            </select>
          </div>

          <div className="box">
            <input id="ville" name="ville" type="text" placeholder="Ville" />
            <label htmlFor="ville">Ville</label>
          </div>
          <button className="btn" type="submit">
            Continuer
          </button>
        </div>
      </FormWrapper>
    </>
  )
}

export default SignupForm
