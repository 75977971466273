import React from 'react'
import styled from 'styled-components/macro'

const Container = styled.div`
  margin: 0 auto 2.4rem;
  display: flex;
  justify-content: center;
  align-items: center;

  .bar {
    height: 4px;
    width: 75%;
    margin-right: 11px;
    background-color: #e5eced;
    border-radius: 2px;
  }

  .filler {
    height: 100%;
    width: ${props => props.completed};
    background-color: ${props => props.bgcolor};
    border-radius: inherit;
    text-align: right;
  }

  .label {
    font-size: 1.4rem;
    font-weight: 700;
  }
`

const ProgressBar = (props) => {
  const { bgcolor, completed } = props
  return (
    <Container completed={completed} bgcolor={bgcolor}>
      <div className='bar'>
        <div className='filler' />
      </div>
      <div className='label'>{completed}</div>
    </Container>
  )
}

export default ProgressBar
