import styled from 'styled-components/macro'

import capture1 from '../assets/images/headway1.png'
import capture2 from '../assets/images/capture2.png'
import capture3 from '../assets/images/image.png'

const ThreeBlocksWrapper = styled.div`
  .all-blocks {
    margin: 0 auto;
  }
  .blocks {
    display: flex;
    padding: 30px 0;
  }
  .info-block {
    width: 50rem;
    padding: 30px 0;
    position: relative;
  }
  h1 {
    font-size: 3rem;
    font-weight: bold;
    line-height: 2.33;
    text-align: center;
    color: #014751;
    text-align: center;
  }


  img {
    width: 100%;
    height: 319px;
    object-fit: cover;
  }
  .img_card {
    width: 494px;
  }
  .info-block h3 {
    width: 50%;
    margin-left: 13rem;
    margin-top: 5rem;    
    font-size: 18px;
    font-weight: bold;
    line-height: 1.44;
    letter-spacing: 0.07px;
    color: #014751;
  }
  .info-block p {
    width: 50%;
    margin-left: 13rem;
    font-size: 16px;
    line-height: 1.63;
    letter-spacing: 0.06px;
    color: #014751;
  }
  .info-block .icon {
    width: 37px;
    height: 37px;
    padding: 9.5px 13.5px 8.5px 14.5px;
    background-color: #5ebdd4;
    border-radius: 50%;
    color: #fff;
    position: absolute;
    left: 15%;
    top: 26%;
  }


  @media (max-width: 900px){
    .img_card {
      width: auto;
     }
    .blocks {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 1rem 0
    }
    .blockCenter{
      display: flex;
      flex-direction: column-reverse;
    }
    .info-block {
      padding: 2rem 0;
      width: inherit;
      .icon {
        position: absolute;
        top: 2%;
        left: 45%;
        }
        p{
          margin-left: auto;
          margin-right: auto;
          text-align: center;
        }
    }
    .info-block h3 {
      margin-left: auto;
      margin-right: auto;
      letter-spacing: 0.07px;
      color: #014751;
      text-align: center;
     }
  }
  @media (max-width: 625px){
    h1 {
      font-size: 1.9rem;
    }
  }
 

`

const ThreeBlocks = () => {
  return (
    <>
      <ThreeBlocksWrapper>
        <div className="all-blocks">
          <h1> Comment devenir client […] </h1>
          <div className="blocks-tree">
            <div className="blocks">
              <div className="info-block">
                <span className="icon">1</span>
                <h3> On commence par un Rendez-vous</h3>
                <p>
                  Nos conseillers sont là pour vous guider dans le choix d’une
                  solution adaptée pour votre entreprise
                </p>
              </div>
              <div className="img-block img_card">
                <img src={capture1} alt="images" />
              </div>
            </div>

            <div className="blocks blockCenter">
              <div className="img-block img_card">
                <img src={capture2} alt="images" />
              </div>
              <div className="info-block">
                <span className="icon">2</span>
                <h3>On vous livre vos cartes après un peu de design</h3>
                <p>
                  Juste le temps de faire des belles cartes à l’image de votre
                  marque.
                </p>
              </div>
            </div>

            <div className="blocks">
              <div className="info-block">
                <span className="icon">3</span>
                <h3>
                  Il n’y a plus qu’à les recharger et c’est prêt à l’emploi
                </h3>
                <p>
                  Depuis votre mobile ou votre ordinateur, vous gérez les
                  cartes.
                </p>
              </div>
              <div className="img-block img_card">
                <img src={capture3} alt="images" />
              </div>
            </div>
          </div>
        </div>
      </ThreeBlocksWrapper>
    </>
  )
}
export default ThreeBlocks
