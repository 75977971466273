import React from 'react'
import styled from 'styled-components/macro'
import { useNavigate } from 'react-router-dom'

import { devices } from '../../theme/defaultTheme'
import { useOffer } from '../../context/OfferProvider'
import { ReactComponent as IconCheck } from '../../assets/images/icons/check.svg'
import OffersData from '../../assets/data/offers.json'

import AvantageClient from '../../components/AvantageClient'
import ThreeBlocks from '../../components/ThreeBlocks'
import OpenAccount from '../../components/OpenAccount'
import Footer from '../../components/Footer'

const OfferWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  max-width: 350px;
  margin: 0 0.7rem;
  padding: 5.5rem 2rem 2rem;
  text-align: center;
  border: 1px solid ${(props) => props.theme.colors.lightGreyColor};
  border-radius: 5px;
  margin-top: 1rem;

  @media ${devices.tablet} {
    flex: 0 0 30%;
  }

  .offer-name {
    font-size: 1.6rem;
    font-weight: 600;
    color: ${(props) => props.theme.colors.primaryColor};
  }
  .offer-price {
    margin: 1rem 0 4rem;
    font-size: 3rem;
    font-weight: 700;
    color: ${(props) => props.theme.colors.primaryColor};
  }
  .offer-img-wrapper {
    margin: 0 auto 4rem;
    width: 70%;
    > img {
      display: block;
      width: 100%;
      height: auto;
    }
  }
  .cta {
    margin-bottom: 4rem;
  }
  .offer-list {
    padding: 0 3rem;
    list-style: none;
  }
  .offer-list-item {
    display: flex;
    align-items: flex-start;
    text-align: left;
    margin-bottom: 1.8rem;
    font-size: 1.3rem;
    line-height: 1.8;
    > svg {
      display: block;
      margin: 7px 10px 0 0;
      flex: 0 0 10px;
    }
  }

  .hot{
    background-color: #ee2464;
    color : #FFF;
    padding: 4px 14px;
    width: 204px;
    border-radius: 12px;
    margin: -4rem 0 0 0;
    text-transform: uppercase;
    font-size: 12px;
  }


  
`

const Offer = ({ action, name, monthlyPrice, imgUrl, options }) => {

  let hot;
  if (name === 'Offre 1') {
    hot = <div className='hot'>Recommandé pour vous </div>
  }

  return (
    <OfferWrapper>
      <div>
        <span>{hot}</span> 
      </div>
      <h2 className="offer-name">{name}</h2>
      <p className="offer-price">{monthlyPrice}€/mois</p>
      <div className="offer-img-wrapper">
        <img src={imgUrl} alt="" />
      </div>
      <button className="cta" onClick={action}>
        Souscrire
      </button>
      <ul className="offer-list">
        {options.map((option, index) => {
          return (
            <li key={`option_${index}`} className="offer-list-item">
              <IconCheck /> {option}
            </li>
          )
        })}
      </ul>
    </OfferWrapper>
  )
}

const SubscriptionPageWrapper = styled.div`
  .subscription-start-title {
    margin-bottom: 4.5rem;
    font-size: 3rem;
    font-weight: 700;
    text-align: center;
    color: ${(props) => props.theme.colors.primaryColor};
  }
  .subscription-choices {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    width: calc(100% + 1rem);
    margin-left: -0.5rem;
  }
`

const SubscriptionPage = () => {
  const { chooseOffer } = useOffer()
  const navigate = useNavigate()

  const startSubscription = (id) => {
    chooseOffer(id)
    navigate('/souscrire')
  }

  return (
    <SubscriptionPageWrapper className="container pageTop">
      <section className="subscription-start">
        <h1 className="subscription-start-title">
          Choisissez votre offre pour commencer la souscription
        </h1>
        <div className="subscription-choices">
          {Object.keys(OffersData).map((offerId) => {
            const { name, monthlyPrice, imgUrl, options } = OffersData[offerId]
            return (
              <Offer
                key={offerId}
                action={() => {
                  startSubscription(offerId)
                }}
                name={name}
                monthlyPrice={monthlyPrice}
                imgUrl={imgUrl}
                options={options}
              />
            )
          })}
        </div>

        <AvantageClient />
        <ThreeBlocks />
        <OpenAccount />
        <Footer />
      </section>
    </SubscriptionPageWrapper>
  )
}

export default SubscriptionPage
