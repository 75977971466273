import React from 'react'
import styled, { keyframes }  from 'styled-components/macro'

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

const LoaderRing = styled.div` 
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
  transform: scale(${props => props.scale});
  transform-origin: top center;
  > div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 8px solid ${props => props.theme.colors.secondaryColor};
    border-radius: 50%;
    animation: ${rotate} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: ${props => props.theme.colors.secondaryColor} transparent transparent transparent;
    &:nth-child(1) {
      animation-delay: -0.45s;
    }
    &:nth-child(2) {
      animation-delay: -0.3s;
    }
    &:nth-child(3) {
      animation-delay: -0.15s;
    }
  }
`
const Loader = ({ scale = 1 }) => {
  return (
    <LoaderRing scale={scale}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </LoaderRing>
  )
}

export default Loader
