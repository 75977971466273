import React from 'react'
import styled from 'styled-components/macro'

import { renderForm } from '../../helpers'

const StepWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 1.5rem;
  width: 38rem;
  margin: 0 auto;
  
  > * {
    grid-column: span 3;
  }

  .substep-title {
    margin-bottom: 2.6rem;
    font-size: 1.6rem;
    font-weight: 700;
    text-align: center;
  }
  
  .substep-description {
    margin-bottom: 2.6rem;
    text-align: center;
  }
  
  .substep-franceconnect {
    margin: 2rem auto;
    width: 200px;
  }
`

const Profile = ({ formFields, title = '', description = '', image = null, errors, touched }) => {
	return (
		<StepWrapper className="step-wrapper">
      {
        title !== '' &&
        <h2 className='substep-title'>{title}</h2>
      }      
      {
        description !== '' &&
        <p className='substep-description'>{description}</p>
      } 
      {
        image &&
        <img className='substep-franceconnect' src={image.url} alt={image.alt} />
      }
      {renderForm(formFields, errors, touched)}
      <div className="form-notice">*Champ obligatoire</div>
    </StepWrapper>
	)
}

Profile.displayName = 'SubscriptionProfile'

export default Profile
