export const defaultTheme = {
  colors: {
    primaryColor: '#014751', /* green */
    secondaryColor: '#2ed8c3', /* light green */
    whiteColor: '#ffffff',
    lightGreyColor: '#e5eced',
    errorColor: '#b70000'
  },
  fonts: {
    fontFamily1: 'Trenda, sans-serif'
  }
}

const sizes = {
  mobile: '425px',
  tablet: '1024px',
  laptop: '1440px',
  desktop: '2560px'
}

export const devices = {
  mobile: `(min-width: ${sizes.mobile})`,
  tablet: `(min-width: ${sizes.tablet})`,
  laptop: `(min-width: ${sizes.laptop})`,
  desktop: `(min-width: ${sizes.desktop})`
};
