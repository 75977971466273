import React from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import styled from 'styled-components/macro'

import Header from '../../components/Header'

const MainSectionWrapper = styled.div`
`

const MainSection = () => {
  const location = useLocation()
	return (
		<MainSectionWrapper>
      <Header showNav={location.pathname !== '/'} />
      <Outlet />
    </MainSectionWrapper>
	)
}

export default MainSection
