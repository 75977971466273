import React from 'react'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components/macro'
import { devices } from '../theme/defaultTheme'

const Nav = styled.nav`
  flex: 1;
  height: 6rem;
  > ul {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    list-style: none;
    margin-left: 0.5rem;
    height: 100%;

    > li {
      margin: 0 0 0 2rem;
      text-align: center;
      height: 100%;
      @media ${devices.tablet} {
        margin: 0 0 0 6rem;
      }
      > a {
        position: relative;
        z-index: 1;
        display: flex;
        align-items: center;
        height: 100%;
        vertical-align: center;
        font-size: 1.6rem;
        font-weight: 500;
        letter-spacing: 0.4px;
        color: ${(props) => props.theme.colors.whiteColor};
        &:after {
          content: '';
          position: absolute;
          z-index: 2;
          bottom: -2px;
          left: -10px;
          display: block;
          height: 4px;
          background-color: ${(props) => props.theme.colors.secondaryColor};
          width: calc(100% + 20px);
          transform: scaleX(0);
          transition: transform 0.1s ease-out;
        }
        &.active,
        &:hover {
          &:after {
            transform: scaleX(1);
          }
        }
        &.disabled {
          pointer-events: none;
          &:hover {
            &:after {
              display: none;
            }
          }
        }
      }
    }
  }

  @media (max-width: 480px){
    .link > a {
            font-size: 1.2rem;
          }
    }
`

const MainNav = () => {
  return (
    <Nav>
      <ul className="mainNav--auth">
        <li className="link">
          <NavLink to={`souscription`} className={({ isActive }) => (isActive ? 'active' : '')}>
            Souscription
          </NavLink>
        </li>
        <li className="link">
          <NavLink
            to={`presentation`}
            className={({ isActive }) => (isActive ? 'active disabled' : 'disabled')}
          >
            Présentation
          </NavLink>
        </li>
        <li className="link">
          <NavLink
            to={`contact`}
            className={({ isActive }) => (isActive ? 'active disabled' : 'disabled')}
          >
            Contact
          </NavLink>
        </li>
      </ul>
    </Nav>
  )
}

export default MainNav
