import React from 'react'
import styled from 'styled-components/macro'

import wifi from '../../assets/images/icons/onde.svg'
import clock from '../../assets/images/icons/clock.svg'
import lock from '../../assets/images/icons/lock.svg'
import idbadge from '../../assets/images/icons/person-badge.svg'

import card from '../../assets/images/card.png'

const Style = styled.div`
.substep {
  position: relative;
  bottom: 2rem;
}
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
  width: 38rem;
  margin: 0 auto;

  .substep-title {
    font-size: 3rem;
    font-weight: 700;
  }
  .btn {
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 20px;
    position: relative;
    right: 4rem;
    top: 2.2rem;
    .change {
      border-radius: 22px;
      border: solid 2px #014751;
      height: 4.5rem;
      font-size: 16px;
      font-weight: 600;
      color: #014751;
      width: 23rem;
    }
  }
  .cta {
    margin-bottom: 0rem;
    width: 23rem;
  }

  .plan {
    display: flex;
    justify-content: center;
    gap: 14rem;
  }
  .plan-cost {
    line-height: 1.63;
    letter-spacing: 0.16px;
    font-size: 4rem;
    font-weight: 700;
    color: #014751;
    position: relative;
    top: 6rem;
    right: 2.4rem;
    .plan-price {
      font-size: 60px;
      letter-spacing: 0.24px;
      border-bottom: solid 3px #297380;
      padding-bottom: 3rem;
    }
  }

  h3 {
    font-size: 1.4rem;
    font-weight: 700;
    line-height: 1.63;
    letter-spacing: 0.06px;
    color: #014751;
    text-align: left;
    margin: 2rem 0;
  }
  .infos-offre {
    margin-top: -9.4rem;
    margin-right: 5rem;
  }

  .offres {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 38px;
  }
  .card{
    padding: 3.4rem 0;
  }
  .icons{
    padding: 0.4rem 0;
  }
  .card-offre {
    padding: 10.4px 17px 31px 12px;
    border-radius: 5px;
    border: solid 1px #e5eced;
    width: 113px;
    height: 104px;
    text-align: left;
    font-size: 1rem;
    p {
      font-size: 1.4rem;
    }
  }

  @media (max-width: 900px){
    .card {
      width: 236px;
    }
   .offres {
      gap: 18px;
    }
    .plan {
      display: flex;
      flex-direction: column-reverse;
    }
    .plan-cost {
      top: -16rem;
      left: 1rem;
    }
    .plan-cost >.plan-price{
      font-size: 40px;
    }
    .blockOffre{
      display: flex;
      flex-direction: column;
    }
    .btn {
      left: 7rem;
      top: -10.8rem;
    }
    .infos-offre {
      margin-top: -13.4rem;
      margin-left: 6rem;
    }
  }

`

const Recap = () => {
  const infos = [
    {
      id: 1,
      text: 'Nominative et prépayée',
      icon: idbadge,
    },
    {
      id: 2,
      text: 'Validité de 2 ans',
      icon: clock,
    },
    {
      id: 3,
      text: 'Sécurisée par un code PIN',
      icon: lock,
    },
    {
      id: 4,
      text: 'Paiement sans contact',
      icon: wifi,
    },
  ]

  return (
    <Style>
      <div className='substep'>
      <h2 className="substep-title">Vous avez choisi l’offre à</h2>
      <div className="elements">
        <div className="plan">
          <div className="plan-cost">
            <span className="plan-price">8€</span>
            <span className="plan-type">/mois</span>
          </div>
          <div>
            <img  className="card" src={card} alt="card" />
          </div>
        </div>
        <div className="plan blockOffre">
          <div className="infos-offre">
            <h3> L’offre comprend :</h3>
            <div className="offres">
              {infos.map((info) => {
                return (
                  <div className="card-offre" key={info.id}>
                    <img  className='icons' src={info.icon} alt="icons" />
                    <p>{info.text}</p>
                  </div>
                )
              })}
            </div>
          </div>
          <div className="btn">
            <button className="cta">Valider cette offre</button>
            <button className="change">Changer d’offre</button>
          </div>
        </div>
      </div>
      </div>
    </Style>
  )
}

Recap.displayName = 'SubscriptionRecap'

export default Recap
