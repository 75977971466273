import { useState, forwardRef } from 'react'
import { Field } from 'formik'
import DatePicker from 'react-datepicker'
import { ReactSearchAutocomplete } from 'react-search-autocomplete'
import styled from 'styled-components/macro'
import cities from './assets/data/cities'
import xmark from '../src/assets/images/icons/xmark.svg'
import { ReactComponent as UploadIcon } from './assets/images/icons/feather-upload.svg'

const InputFile = ({ name, fieldLabel, classes, isErrorField, fieldError }) => {
  const [filename, setFilename] = useState('')

  const handleDeleteFile = () => {
    setFilename(null)
  }

  return (
    <div
      key={name}
      className={`input-wrapper input-file ${classes} ${filename ? 'is-valid' : ''} ${
        isErrorField ? 'input-error' : ''
      }`}
    >
      <div className="input-wrapper-inner">
        <Field
          id={name}
          name={name}
          type="file"
          validate={() => (filename ? '' : 'Ce champ est obligatoire')}
          onChange={(e) => setFilename(e.target.files[0]?.name)}
        />
        <label className="input-label" htmlFor={name}>
          {fieldLabel}
        </label>
        <UploadIcon />
        <div className="input-file-filename">
          {filename}
          <>
            {filename && (
              <button className="btn-delete" onClick={() => setFilename(handleDeleteFile)}>
                <img className="icon-delete" src={xmark} alt="delete" />
              </button>
            )}
          </>
        </div>
      </div>
      {isErrorField && <p className="input-error-message">{fieldError}</p>}
    </div>
  )
}

const InputDate = ({ name, placeholder, classes, isErrorField, fieldError }) => {
  const [startDate, setStartDate] = useState(null)

  const CustomInput = forwardRef(({ value, onClick }, ref) => (
    <Field
      name={name}
      validate={() => (value ? '' : 'Ce champ est obligatoire')}
      value={value}
      as={() => (
        <input
          ref={ref}
          value={value}
          placeholder={placeholder}
          onClick={onClick}
          readOnly={true}
        />
      )}
    />
  ))

  return (
    <div key={name} className={`input-wrapper ${classes} ${isErrorField ? 'input-error' : ''}`}>
      <DatePicker
        selected={startDate}
        maxDate={new Date()}
        onChange={(date) => setStartDate(date)}
        showYearDropdown
        dateFormatCalendar="MMMM"
        yearDropdownItemNumber={60}
        scrollableYearDropdown
         dropdownMode="select"
        popperPlacement="top-end"
        customInput={<CustomInput />}
      />
      {isErrorField && <p className="input-error-message">{fieldError}</p>}
    </div>
  )
}

const StyleAutocomplete = styled.div`
  position: relative;
  z-index: 2;

  .search-icon,
  .clear-icon {
    display: none;
  }

  input,
  input::placeholder,
  ul {
    color: #014751 !important;
    font-size: 1.4rem;
  }

  .input-label {
    opacity: 0;
  }

  .input-label.is-upon {
    opacity: 1;
  }

  .wrapper {
    border: none !important;
    box-shadow: none !important;
  }
`

const Autocomplete = ({ name, placeholder, classes, isErrorField, fieldError }) => {
  const [value, setValue] = useState('')
  const [selectedCity, setSelectedCity] = useState(null)

  return (
    <StyleAutocomplete
      key={name}
      className={`input-wrapper autocomplete ${classes} ${isErrorField ? 'input-error' : ''}`}
    >
      <ReactSearchAutocomplete
        showIcon={false}
        items={cities}
        placeholder={placeholder}
        onSelect={(city) => {
          setSelectedCity(city)
          setValue(city.name)
        }}
        onSearch={(words) => {
          if (value !== selectedCity?.name) {
            setSelectedCity(null)
          }
          setValue(words)
        }}
      />
      <Field
        name={name}
        validate={() => (value === selectedCity?.name ? '' : 'Merci de saisir les premières lettres de la ville ')}
        as={() => <input type="hidden" />}
      />

      <label className={`input-label ${value ? 'is-upon' : ''}`} htmlFor={name}>
        {placeholder}
      </label>

      {isErrorField && <p className="input-error-message">{fieldError}</p>}
    </StyleAutocomplete>
  )
}

export const renderForm = (formFields, errors, touched) => {
  return formFields.map((field, key) => {
    const {
      type,
      name,
      maxLength,
      classes = '',
      hideLabel = false,
      placeholder = '',
      mandatory
    } = field
    const fieldError = errors[name] || ''
    const isErrorField = touched[name] && fieldError !== ''
    const fieldLabel = (field.label || '') + (mandatory ? '*' : '')

    switch (type) {
      case 'radio':
      case 'checkbox':
        return (
          <div
            key={name}
            className={`${type}-group ${classes} ${field.style === 'inline' ? 'is-inline' : ''}`}
            role="group"
            aria-label={fieldLabel}
          >
            {field.options.map((option, i) => {
              return (
                <div key={`${name}_option_${i}`} className={`${type}-wrapper`}>
                  <Field type={type} id={`${name}_option_${i}`} name={name} value={option.value} />
                  <label className="input-label" htmlFor={`${name}_option_${i}`}>
                    {option.label}
                  </label>
                </div>
              )
            })}
            {isErrorField && <p className="input-error-message">{fieldError}</p>}
          </div>
        )
      case 'select':
        return (
          <div
            key={name}
            className={`select-wrapper ${classes} ${isErrorField ? 'select-error' : ''}`}
          >
            {fieldLabel && (
              <label className="select-label" htmlFor={name}>
                {fieldLabel}
              </label>
            )}
            <Field name={name} as="select">
              {field.options.map((option, i) => {
                return (
                  <option key={`${name}_option_${i}`} value={option.value}>
                    {option.label}
                  </option>
                )
              })}
            </Field>
            {isErrorField && <p className="select-error-message">{fieldError}</p>}
          </div>
        )
      case 'section':
        return (
          <div key={`section-title-${key}`} className={`section-title`}>
            <p>{fieldLabel}</p>
          </div>
        )
      case 'datepicker':
        return (
          <InputDate
            key={name}
            name={name}
            classes={classes}
            placeholder={placeholder || fieldLabel}
            isErrorField={isErrorField}
            fieldError={fieldError}
          />
        )
      case 'autocomplete':
        return (
          <Autocomplete
            key={name}
            name={name}
            classes={classes}
            placeholder={placeholder || fieldLabel}
            isErrorField={isErrorField}
            fieldError={fieldError}
          />
        )
      case 'file':
        return (
          <InputFile
            key={name}
            name={name}
            classes={classes}
            fieldLabel={fieldLabel}
            isErrorField={isErrorField}
            fieldError={fieldError}
          />
        )
      default:
        return (
          <div
            key={name}
            className={`input-wrapper ${classes} ${isErrorField ? 'input-error' : ''}`}
          >
            <Field
              id={name}
              name={name}
              placeholder={placeholder || fieldLabel}
              maxLength={maxLength}
            />
            {!hideLabel && (
              <label className="input-label" htmlFor={name}>
                {fieldLabel}
              </label>
            )}
            {isErrorField && <p className="input-error-message">{fieldError}</p>}
          </div>
        )
    }
  })
}
