import styled from 'styled-components/macro'

const OpenAccountWrapper = styled.div`
  .souscription {
    height: 349px;
    margin-top: 3rem;
    padding: 42px 0;

    &:before {
      content: ' ';
      position: absolute;
      left: 0;
      width: 100vw;
      height: inherit;
      background-color: rgba(46, 216, 195, 0.05);
      z-index: -1;
    }

    h1 {
      padding: 2rem 0;
      font-size: 3rem;
      font-weight: bold;
      line-height: 2.11;
      letter-spacing: 0.12px;
      text-align: center;
      color: #014751;
    }
    p {
      margin: 0 10rem;
      font-size: 16px;
      letter-spacing: 0.06px;
      text-align: center;
      color: #014751;
    }
  }
  .btn {
    display: flex;
    justify-content: center;
    margin-top: 5rem;
    .check {
      padding: 0 1.4rem;
      border-radius: 22px;
      border: solid 2px #014751;
      height: 4.5rem;
      font-size: 1.5rem;
      font-weight: 600;
      color: #014751;
    }
    .cta {
      margin-left: 1rem;
    }
  }

  @media (max-width: 625px) {
    .souscription h1 {
      font-size: 1.9rem;
    }
    .souscription p {
      margin: 0;
      letter-spacing: 0;
      padding: 0 12%;
    }
    .souscription {
      margin-top: 0;
      padding: 0;
    }
    .cta {
      margin-left: 0;
    }
    .btn {
      display: grid;
      gap: 16px;
      justify-content: center;
    }
  }

  @media (max-width: 400px) {
    .souscription {
      height : 400px
    }
  }
  
`

const OpenAccount = () => {
  return (
    <>
      <OpenAccountWrapper>
        <div className="souscription">
          <h1> Ouvrez votre compte […] dès maintenant ! </h1>
          <p>
            Concentrez-vous sur votre comptabilité et pas l’inverse. Optimisez-votre travail en
            quelques clics.
          </p>
          <div className="btn">
            <button className="check">Suivre mon dossier</button>
            <button className="cta">Souscrire</button>
          </div>
        </div>
      </OpenAccountWrapper>
    </>
  )
}
export default OpenAccount
