import React from 'react'
import { useState } from 'react'
import styled from 'styled-components/macro'
import download from '../../src/assets/images/icons/document-download.svg'
import xmark from '../../src/assets/images/icons/icon-cross.svg'
import Modal from './Modal'
const ContartWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
  margin: 0 auto;

  h1 {
    font-size: 2.7rem;
    font-weight: 700;
    line-height: 1.63;
    letter-spacing: 0.06px;
    color: #014751;
    text-align: center;
    margin: -4rem 4rem 3rem;
  }
  .plan-sigenr {
    width: 100%;
    height: 371px;
    background-color: #efefef;
    span {
      display: block;
      float:  center;
      padding: 16rem 0;
      text-transform: uppercase;
      font-size: 2rem;
      font-weight: 700;
      color: #707070;
      line-height: 1.32;
      opacity: 0.5;
    }
  }

  .btnValide {
    position: relative;
    cursor: pointer;
    overflow: hidden;
    padding: 10px 0;
    height: 4.5rem;
    vertical-align: middle;
    border-radius: 2.4rem;
    text-align: center;
    color: ${(props) => props.theme.colors.primaryColor};
    border: 1px solid ${(props) => props.theme.colors.secondaryColor};
    font-size: 1.6rem;
    font-weight: 600;
    transition: color 0.2s ease-in-out;
    width: 150px;
    margin-top: 1.4rem;
    margin-left: auto;
    margin-right: auto;
    :before {
      content: '';
      display: block;
      position: absolute;
      z-index: -1;
    }
    &:after {
      content: '';
      display: block;
      position: absolute;
      z-index: -1;
      top: 0;
      right: 0;
      bottom: auto;
      left: 0;
      height: 300%;
      width: 100%;
      background: ${(props) => props.theme.colors.secondaryColor};
      transition: height 0.3s ease-in-out;
    }
    &:hover {
      color: ${(props) => props.theme.colors.secondaryColor};
      &:after {
        height: 0;
      }
    }
  }
  .download {
    position: relative;
    bottom: 6rem;
  }
  .title {
    position: relative;
    margin-top: 10rem;
    h1 {
      font-size: 30px;
    }
  }
 p {
    margin-top: -2rem;
  }
  .footer > .cta {
    margin-top: 3rem;
  }

  @media (max-width: 750px){
    .modalContainer {
      height: 395px;
    }
    .title> h1 {
      font-size: 18px;
      margin: 2rem 0;
    }
     .download {
        bottom: 0rem; 
    }
    .title {
      margin-top: 6rem;
    }
  }

`

function SignerContart() {
  
  const [openModal, setOpenModal] = useState(false)

  return (
    <ContartWrapper>
      <div className="plan-contrat">
        <div className="plan-sigenr">
          <span>
            Solution signature <br /> numérique externe
          </span>
        </div>
      </div>
      <div
        className="btnValide"
        onClick={() => {
          setOpenModal(true)
        }}
      >
        Je valide
      </div>
      {openModal && (
        <Modal>
           <button className='btn-close'
                 onClick={() => {
                  setOpenModal(false)
                 }}
               >
                 <img src={xmark} alt="close" />
               </button>
          <div className="title">
            <img className="download" src={download} alt="download" />
            <h1>Finalisation de votre contrat</h1>
          </div>
          <div className="body">
            <p>Vous pouvez maintenant télécharger votre contrat</p>
          </div>
          <div className="footer">
            <button className="cta ">Je télécharge</button>
          </div>
        </Modal>
      )}
    </ContartWrapper>
  )
}
export default SignerContart
