import React from 'react'
import styled from 'styled-components/macro'
import { Link } from 'react-router-dom'

import MainNav from './MainNav'
import Logo from '../assets/images/logo@3x.png'

const AppHeader = styled.header`
  margin-bottom: 5rem;
  height: 9rem;
  background-color: ${props =>  props.theme.colors.primaryColor };

  .AppHeader__contentWrapper {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    height: 100%;
  }
  .AppHeader__title {
    width: 15rem;
    a {
      display: block;
      width: 100%;
    }
    img {
      display: block;
      width: 100%;
      height: auto;
      margin: -1rem 0;
    }
  }
  @media (max-width: 500px){
    header:after {
      content: " ";
      position: absolute;
      left: 0;
      width: 100vw;
      height: inherit;
    }
   
`

const Header = ({ showNav }) => {
	return (
		<AppHeader withBackground={showNav}>
      <div className='AppHeader__contentWrapper container'>
        <h1 className='AppHeader__title'>
          <Link to='/'>
            <img src={Logo} alt='' />
          </Link>
        </h1>
        {
          showNav &&
          <MainNav />
        }
      </div>
    </AppHeader>
	)
}

export default Header
