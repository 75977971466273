import React from 'react'
import {Link} from 'react-router-dom'
import styled from 'styled-components/macro'
import SuccessIcon from '../../assets/images/icons/merci.svg'

const StyleSuccess = styled.div`
  .container-success {
    margin-top: 8rem;
    text-align: center;
  }

  .merci {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 90px;
    height: 90px;
  }
  h1 {
    font-size: 60px;
    font-weight: bold;
    line-height: 1.08;
    letter-spacing: 0.24px;
    text-align: center;
    color: #014751;
    padding: 10px 0;
  }
  p {
    margin: 18px 35px;
    font-family: Trenda;
    font-size: 2rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 0.12px;
    text-align: center;
    color: #014751;
  }
  .cta {
    display: block;
    position: relative;
    margin-top: 3rem;
    margin-left: auto;
    margin-right: auto;
  }
`

const Success = () => {

  return (
    <StyleSuccess>
      <div className="container-success">
        <img className="merci" src={SuccessIcon} alt="Success" />
        <h1>Merci !</h1>
        <p>
          Votre contrat a bien été souscrit. <br />
          Vous allez recevoir un email de confirmation
        </p>
        <Link to='/souscription' className="cta">Retour sur la page d’accueil</Link>
      </div>
    </StyleSuccess>
  )
}

Success.displayName = 'SubscriptionSuccess'

export default Success
